import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { Puff } from 'react-loading-icons'
import toast from 'react-hot-toast'
import {
  // AddressElement,
  // LinkAuthenticationElement,
  // ExpressCheckoutElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { Button } from '../form/button/Button'
import { ThemeInterface } from '../../lib/interfaces'
import { PaymentIntentOrSetupIntentResult } from '@stripe/stripe-js'
import TextInputField from '../form/TextInputField'
import { isEmailValid } from '../../lib/utils'
// import { useUser } from '../../lib/store'

export type OnIntentRequestCallback = (
  data: OnIntentHandlerReponse
) => Promise<OnIntentSuccess | undefined>

export type LoadingFunction = (externalIsLoading: boolean) => Promise<void>
export type DialogError = (message: string) => Promise<void>

export interface OnIntentRequestError {
  message: string
}

export interface OnIntentSuccess {
  paymentMethod?: string | null
  finished: () => void
}

export interface CardCaptureDialogOptions {
  showSkip?: boolean
  proceedText?: string
  title?: string
  requestAddress?: boolean
}

export interface OnIntentData {
  email?: string
}

export interface CardCaptureDialogProps {
  show: boolean
  theme?: ThemeInterface
  options?: CardCaptureDialogOptions
  onIntentRequestHandler: (
    callback: OnIntentRequestCallback,
    data?: OnIntentData,
    setLoading?: LoadingFunction,
    setError?: DialogError
  ) => void
  onCancel?: () => void
  onError?: (data: OnIntentRequestError) => void
  onClose?: () => void
  onSkip?: () => void
}

export interface OnIntentHandlerReponse {
  clientSecret: string
  type: string
  returnUrl: string
}

export function CardCaptureDialog(props: CardCaptureDialogProps) {
  const [email, setEmail] = useState<string | undefined>()
  const [open, setOpen] = useState(props.show)
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [dialogErrorMessage, setDialogErrorMessage] = useState<
    string | undefined
  >()
  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    console.log('props changed?', props)
    setOpen(props.show)
  }, [props])

  useEffect(() => {
    console.log('open changed state', open) //
    // if (open === false) {
    //   props.onClose && props.onClose();
    // }
  }, [open, props])

  const closeDialog = () => {
    if (isSaving) {
      return
    }
    setOpen(false)
    props.onClose && props.onClose()
  }
  const finished = () => {
    setIsSaving(false)
    setOpen(false)
    props.onClose && props.onClose()
  }
  const skipDialog = () => {
    setOpen(false)
    props.onSkip && props.onSkip()
  }
  const cancel = () => {
    props.onCancel && props.onCancel()
    finished()
    setOpen(false)
  }

  const onIntentHandlerReturn = async (
    data: OnIntentHandlerReponse
  ): Promise<OnIntentSuccess | undefined> => {
    if (stripe && elements) {
      const confirmIntent =
        data.type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment
      // Confirm the Intent using the details collected by the Payment Element
      const intentResponse: PaymentIntentOrSetupIntentResult =
        await confirmIntent({
          elements,
          clientSecret: data.clientSecret,
          redirect: 'if_required',
          confirmParams: {
            return_url: data.returnUrl,
          },
        })

      setIsLoading(false)
      setIsSaving(false)
      if (intentResponse.error) {
        setDialogErrorMessage(intentResponse.error.message)

        if (!props.onError) {
          toast.error(
            intentResponse.error.message ||
              'Oops, something went wrong. Please contact support@bloodygoodtests.com.au',
            {
              duration: 10000,
            }
          )
        } else {
          props.onError({
            message:
              intentResponse.error.message ||
              'Oops, something went wrong. Please contact support@bloodygoodtests.com.au',
          })
        }
        return
      }
      console.log('hide dialog error message')
      setDialogErrorMessage(undefined)
      // console.log("close dialog");
      // closeDialog();
      return {
        finished: finished,
        paymentMethod: intentResponse.paymentIntent
          ? intentResponse.paymentIntent.payment_method?.toString()
          : intentResponse.setupIntent.payment_method?.toString(),
      }
    }
  }

  const handleConfirmationDialog = async (): Promise<void> => {
    console.log('we hit the okay button... lets submit now')
    // if (!org) {
    //   throw new Error("Missing Org");
    // }
    // if (!stripe || !elements) {
    //   throw new Error("Stripe missing");
    // }

    if (elements == null) {
      return
    }

    console.log('lets now handle the new billing info.')

    // // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      // Show error to your customer
      console.log('an error happened', submitError)
      return
    }

    setIsSaving(true)

    props.onIntentRequestHandler(
      onIntentHandlerReturn,
      {
        email,
      },
      async (externalIsLoading: boolean) => {
        setIsLoading(externalIsLoading)
      },
      async (message: string) => {
        setDialogErrorMessage(message)
      }
    )
    // try {
    //   const response = await updateCardRequest(org);

    //   // Confirm the Intent using the details collected by the Payment Element
    //   const intentResponse = await stripe.confirmSetup({
    //     elements,
    //     clientSecret: response.clientSecret,
    //     redirect: "if_required",
    //     confirmParams: {
    //       return_url: `${import.meta.env.VITE_APP_URL}/org/${org.id}`,
    //     },
    //   });
    //   if (intentResponse.error) {
    //     throw new Error(
    //       intentResponse.error.message ||
    //         "An error occured, please contact support."
    //     );
    //   }
    //   if (intentResponse.setupIntent.status !== "succeeded") {
    //     throw new Error("Something went wrong. please contact support");
    //   }
    //   if (!intentResponse.setupIntent.payment_method) {
    //     throw new Error("Missing payment method.");
    //   }
    //   // const pi = intentResponse.paymentIntent.payment_method
    //   const sub = await updateCard(
    //     org,
    //     intentResponse.setupIntent.payment_method.toString()
    //   );
    //   setOpen(false);
    //   setDialogErrorMessage(undefined);
    //   setIsSaving(false);
    //   toast.success("Card updated", {
    //     duration: 3000,
    //   });
    //   setCurrentBilling(sub);
    //   actions.setCurrentBilling(sub);

    //   // console.log("intent response", intentResponse);
    //   // setIsSaving(true);
    // } catch (e) {
    //   let message = "An error occured";
    //   if (e instanceof Error) {
    //     message = e.message;
    //   }
    //   setDialogErrorMessage(message);
    //   setIsSaving(false);
    // }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-10 ${isSaving ? 'cursor-progress' : ''}`}
        // initialFocus={cancelButtonRef}
        onClose={closeDialog}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div
          className={`fixed inset-0 z-10 w-screen overflow-y-auto ${
            isSaving ? 'cursor-progress' : ''
          }`}
        >
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm ${
                  isSaving ? 'cursor-progress' : ''
                }`}
              >
                {(isSaving || isLoading) && (
                  <div className="absolute w-full h-full bg-white/50 z-50 flex justify-center items-center ">
                    <Puff
                      height={100}
                      width={100}
                      speed={0.8}
                      stroke="#FF3A5F"
                      fill="#FF3A5F"
                    />
                  </div>
                )}
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-center">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {props.options?.title || ''}
                      </Dialog.Title>
                      <div className="mt-2">
                        {/* <ExpressCheckoutElement
                          onConfirm={(event) => console.log("onconfirm", event)}
                        /> */}

                        {/* <LinkAuthenticationElement
                          onChange={(event) => {
                            setEmail(event.value.email)
                          }}
                        /> */}
                        <div className="col-span-2">
                          <TextInputField
                            id="billing-email"
                            name="billing-email"
                            type="email"
                            label="Billing Email"
                            value={email}
                            handleChange={(d) => setEmail(d.strValue)}
                          ></TextInputField>
                        </div>
                        <div className="mb-6 text-sm text-gray-semi-dark">
                          This is your billing email, all receipt and invoice
                          emails will be sent here.
                        </div>

                        <PaymentElement onReady={() => setIsLoading(false)} />
                        {/* <AddressElement
                          options={{
                            mode: 'billing',
                            defaultValues: {
                              name: [user?.firstName, user?.lastName].join(' '),
                              address: {
                                country: 'AU',
                              },
                            },
                          }}
                        ></AddressElement> */}
                        {dialogErrorMessage && (
                          <div className="text-blood font-semibold text-sm p-4 border border-blood rounded-md bg-blood/5">
                            ⚠️ {dialogErrorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse sm:px-6 items-stretch">
                  <Button
                    label={
                      props.options?.proceedText
                        ? props.options.proceedText
                        : 'Continue'
                    }
                    type="button"
                    color="jade"
                    onClick={() => handleConfirmationDialog()}
                    disabled={
                      isSaving ||
                      isLoading ||
                      !email ||
                      (email && !isEmailValid(email)) ||
                      false
                    }
                    theme={props.theme}
                  ></Button>
                  <Button
                    label="Cancel"
                    type="button"
                    classNames="mr-2"
                    onClick={() => cancel()}
                    disabled={isSaving}
                  ></Button>
                  {props.options?.showSkip && (
                    <Button
                      label="Skip"
                      type="button"
                      color="plain"
                      onClick={() => skipDialog()}
                      disabled={isSaving}
                      style={{
                        position: 'absolute',
                        left: '10px',
                      }}
                    ></Button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
