// import React, { PropsWithChildren } from 'react';
import { FunctionComponent, useEffect, useState } from 'react'
import DesktopSidebar from './DesktopSidebar'
import MobileSidebar from './MobileSidebar'
import { SidebarProps } from '../../lib/interfaces'
import { useCurrentPage, useUser } from '../../lib/store'
import { useOrganisationContext } from '../context/OrganisationContext'

import {
  MenuItemGroup,
  adminMenuItems,
  externalMenuItems,
  menuItems,
} from './MenuItems'
import {
  organisationMenuItems,
  organisationSettingsMenuItems,
} from './OrganisationMenuItems'

const Sidebar: FunctionComponent<SidebarProps> = ({
  setSidebarOpen,
  sidebarOpen,
  theme,
  urlPrefix,
}) => {
  const currentPage = useCurrentPage()
  const user = useUser()
  const [menuList, setMenuList] = useState<MenuItemGroup[]>([])
  const { org } = useOrganisationContext()

  const isAdminSection = currentPage.indexOf('admin') === 0

  useEffect(() => {
    if (isAdminSection) {
      setMenuList([
        {
          header: '',
          items: adminMenuItems(user, currentPage),
        },
      ])
    } else if (!org) {
      setMenuList([
        {
          header: '',
          items: menuItems(currentPage),
        },
        {
          header: 'Support',
          items: externalMenuItems(user, currentPage),
        },
      ])
    } else {
      setMenuList([
        {
          header: '',
          items: organisationMenuItems(currentPage, urlPrefix),
        },
        {
          header: 'Settings',
          items: organisationSettingsMenuItems(currentPage, urlPrefix),
        },
        {
          header: 'Support',
          items: externalMenuItems(user, currentPage),
        },
      ])
    }
  }, [currentPage, org, user, urlPrefix, isAdminSection])

  return (
    <>
      <MobileSidebar
        setSidebarOpen={setSidebarOpen}
        sidebarOpen={sidebarOpen}
        menuItemGroups={menuList}
        theme={theme}
        urlPrefix={urlPrefix}
      ></MobileSidebar>
      <DesktopSidebar
        menuItemGroups={menuList}
        theme={theme}
        urlPrefix={urlPrefix}
      ></DesktopSidebar>
    </>
  )
}

export default Sidebar
