import { Fragment, useEffect, useState } from 'react'
import { BiomarkerItem } from '../../lib/validators'
import TextInputField, { onChangeInterface } from '../form/TextInputField'
import { Textarea } from '../form/Textarea'

interface AdminBiomarkerElementProps {
  biomarker: BiomarkerItem
  value?: string
  lowRef?: string
  highRef?: string
  description?: string
  lowRefNotes?: string
  midRefNotes?: string
  highRefNotes?: string
  showDescriptionFields?: boolean
  handleChange?: (data: onChangeInterface) => void
}
interface RefRanges {
  refLow?: string
  refHigh?: string
  value?: string
}

export default function AdminBiomarkerElement({
  biomarker,
  value,
  lowRef,
  highRef,
  description,
  lowRefNotes,
  highRefNotes,
  midRefNotes,
  showDescriptionFields,
  handleChange,
}: AdminBiomarkerElementProps) {
  // console.log("biomarker", biomarker, description);
  const [refRanges, setRefRanges] = useState<RefRanges>({})
  const [abnormal, setAbnormal] = useState(false)
  const [lowNotes, setLowNotes] = useState<string>(
    lowRefNotes || biomarker.refIntervalLowNotes || ''
  )
  const [midNotes, setMidNotes] = useState<string>(
    midRefNotes || biomarker.refIntervalMidNotes || ''
  )
  const [highNotes, setHighNotes] = useState<string>(
    highRefNotes || biomarker.refIntervalHighNotes || ''
  )
  const [iDescription, setDescription] = useState<string>(
    description || biomarker.description || ''
  )

  const onChangeDescription = (data: onChangeInterface) => {
    setDescription(data.strValue)
  }
  const onChangeLowNotes = (data: onChangeInterface) => {
    setLowNotes(data.strValue)
  }
  const onChangeHighNotes = (data: onChangeInterface) => {
    setHighNotes(data.strValue)
  }
  const onChangeMidNotes = (data: onChangeInterface) => {
    setMidNotes(data.strValue)
  }

  useEffect(() => {
    setDescription(description || biomarker.description || '')
    setHighNotes(highRefNotes || biomarker.refIntervalLowNotes || '')
    setLowNotes(lowRefNotes || biomarker.refIntervalHighNotes || '')
    setMidNotes(midRefNotes || biomarker.refIntervalMidNotes || '')
  }, [lowRefNotes, midRefNotes, highRefNotes, description, biomarker])

  useEffect(() => {
    setRefRanges({
      refHigh: highRef || undefined,
      refLow: lowRef || undefined,
      value: value || undefined,
    })
  }, [highRef, lowRef, value])

  const onChangeLow = (data: onChangeInterface) => {
    let val: string | undefined = undefined
    if (data.value === '') {
      val = undefined
    } else {
      val = data.value.toString().replace(/[^0-9.]+/gi, '')
    }

    setRefRanges((prevState) => {
      const state = { ...prevState }
      if (val === undefined) {
        delete state.refLow
      } else {
        state.refLow = val
      }
      return state
    })
    if (handleChange) handleChange(data)
  }
  const onChangeHigh = (data: onChangeInterface) => {
    let val: string | undefined = undefined
    if (data.value === '') {
      val = undefined
    } else {
      val = data.value.toString().replace(/[^0-9.]+/gi, '')
    }

    setRefRanges((prevState) => {
      const state = { ...prevState }
      if (val === undefined) {
        delete state.refHigh
      } else {
        state.refHigh = val
      }
      return state
    })
    if (handleChange) handleChange(data)
  }

  const onChangeValue = (data: onChangeInterface) => {
    let val: string | undefined = undefined
    if (data.value === '') {
      val = undefined
    } else {
      val = data.value.toString().replace(/[^0-9.]+/gi, '')
    }

    setRefRanges((prevState) => {
      const state = { ...prevState }
      if (val === undefined) {
        delete state.value
      } else {
        state.value = val
      }
      return state
    })
    if (handleChange) handleChange(data)
  }

  useEffect(() => {
    if (
      refRanges.refHigh !== undefined &&
      refRanges.refLow !== undefined &&
      refRanges.value !== undefined
    ) {
      if (
        parseFloat(refRanges.value) > parseFloat(refRanges.refHigh) ||
        parseFloat(refRanges.value) < parseFloat(refRanges.refLow)
      ) {
        setAbnormal(true)
        return
      }
      setAbnormal(false)
    }

    if (
      refRanges.refHigh !== undefined &&
      refRanges.refLow === undefined &&
      refRanges.value !== undefined
    ) {
      if (parseFloat(refRanges.value) > parseFloat(refRanges.refHigh)) {
        setAbnormal(true)
        return
      }
      setAbnormal(false)
    }

    if (
      refRanges.refHigh === undefined &&
      refRanges.refLow !== undefined &&
      refRanges.value !== undefined
    ) {
      if (parseFloat(refRanges.value) < parseFloat(refRanges.refLow)) {
        setAbnormal(true)
        return
      }
      setAbnormal(false)
    }
  }, [refRanges])

  return (
    <div
      className={
        abnormal
          ? '!ring-blood focus:ring-blood ring-2 bg-blood/10 rounded-lg p-4 relative'
          : 'p-4'
      }
    >
      {abnormal && (
        <div className="absolute bg-blood text-white text-sm top-0 right-0 font-bold px-2 rounded-lg rounded-tl-none rounded-br-none">
          Abnormal
        </div>
      )}
      <TextInputField
        id={`biomarker_${biomarker.id}`}
        label={biomarker.name}
        name={`biomarker_${biomarker.id}`}
        type="text"
        tooltip={biomarker.commonName}
        handleChange={onChangeValue}
        required={true}
        unit={biomarker.unit}
        inputmode="numeric"
        pattern="[\d.]*"
        value={refRanges.value ? refRanges.value.toString() : ''}
      />
      <div className="grid grid-cols-2 pt-2 gap-4">
        <div>
          <TextInputField
            id={`biomarker_${biomarker.id}_ref-low`}
            name={`biomarker_${biomarker.id}_ref-low`}
            type="text"
            tooltip="Ref Interval Low"
            handleChange={onChangeLow}
            inputmode="numeric"
            pattern="[\d.]*"
            // placeholder={biomarker.refIntervalLow?.toString()}
            value={refRanges.refLow ? refRanges.refLow.toString() : ''}
          />
        </div>
        <div>
          <TextInputField
            id={`biomarker_${biomarker.id}_ref-high`}
            name={`biomarker_${biomarker.id}_ref-high`}
            type="text"
            inputmode="numeric"
            pattern="[\d.]*"
            tooltip="Ref Interval High"
            handleChange={onChangeHigh}
            // placeholder={biomarker.refIntervalHigh?.toString()}
            value={refRanges.refHigh ? refRanges.refHigh.toString() : ''}
          />
        </div>
      </div>
      {showDescriptionFields && (
        <Fragment>
          <div className="pt-2">
            <Textarea
              id={`biomarker_${biomarker.id}_description`}
              name={`biomarker_${biomarker.id}_description`}
              label="Biomarker Description"
              value={iDescription}
              handleChange={onChangeDescription}
            ></Textarea>
          </div>
          <div className="pt-2">
            <Textarea
              id={`biomarker_${biomarker.id}_low-ref-notes`}
              name={`biomarker_${biomarker.id}_low-ref-notes`}
              label="Low Result Reference Notes"
              value={lowNotes}
              handleChange={onChangeLowNotes}
            ></Textarea>
          </div>
          <div className="pt-2">
            <Textarea
              id={`biomarker_${biomarker.id}_mid-ref-notes`}
              name={`biomarker_${biomarker.id}_mid-ref-notes`}
              label="Mid Result Reference Notes"
              value={midNotes}
              handleChange={onChangeMidNotes}
            ></Textarea>
          </div>
          <div className="pt-2">
            <Textarea
              id={`biomarker_${biomarker.id}_high-ref-notes`}
              name={`biomarker_${biomarker.id}_high-ref-notes`}
              label="High Result Reference Notes"
              value={highNotes}
              handleChange={onChangeHighNotes}
            ></Textarea>
          </div>
        </Fragment>
      )}
    </div>
  )
}
