import { PageHeading } from '../../../layout/PageHeading'
import PageSection from '../../../layout/PageSection'
import { useOrganisationContext } from '../../../context/OrganisationContext'
import {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { useLocation } from 'react-router-dom'
import { Tab } from '../../../../lib/interfaces'

import { setDocumentTitle } from '../../../../lib/utils'
import { orgSettingsRegex } from '../../../../lib/routes'
import { SettingsTabGeneral } from './settings-tabs/SettingsTabGeneral'
import { SettingsTabApikey } from './settings-tabs/SettingsTabApi'
import { SettingsTabBilling } from './settings-tabs/SettingsTabBilling'
import { Elements } from '@stripe/react-stripe-js'
import { stripeOptions, stripePromise } from '../../../../lib/billing'
import SettingsTabBranding from './settings-tabs/SettingsTabBranding'
import OrgPage from '../../../OrgPage'
import { TabbedMenu } from '../../../layout/TabbedMenu'

const baseTabs: Record<string, Tab> = {
  '': {
    name: 'General',
    href: '',
    current: false,
  },
  branding: {
    name: 'Branding',
    href: 'branding',
    current: false,
  },
  apikey: {
    name: 'Api Keys',
    href: 'apikey',
    current: false,
  },
  billing: {
    name: 'Billing',
    href: 'billing',
    current: false,
  },
}

export interface SettingsTabProps extends PropsWithChildren {}

export function OrgSettings() {
  const location = useLocation()
  const { org, theme } = useOrganisationContext()
  const [currentPage, setCurrentPage] = useState<string>('')
  const [tabs, setTabs] = useState<Record<string, Tab>>(baseTabs)
  const [tabsUpdated, setTabsUpdated] = useState(0)

  setDocumentTitle('Settings', 'BRANDED')

  const getTabs = useCallback(
    (page: string) => {
      Object.entries(baseTabs).map(([key, val]) => {
        val.current = false
        if (key === page) {
          val.current = true
        }

        return val
      })
      return tabs
    },
    [tabs]
  )

  const handlePageLoad = useCallback(
    (pathname: string): void => {
      // console.log("++++ handle page load");
      const re = new RegExp(orgSettingsRegex)
      const matches = re.exec(pathname)
      // console.log("matches", matches, re.test(location.pathname));
      if (!matches || matches.length === 0) {
        console.log(pathname, orgSettingsRegex)
        throw new Error(`Tab not found: ${pathname}`)
      }

      const tab = matches.pop()?.replace('/', '')
      if (tab === undefined) {
        throw new Error('Tab not found')
      }

      // const currentTab = Object.entries(tabs).filter(([key]) => {
      //   return key === tab;
      // });
      // console.log("the current tab is", currentTab[0][0], currentTab[0][1]);
      // setCurrentPage(currentTab[0][1].component);
      setCurrentPage(tab)

      const latestTabs = getTabs(tab)
      setTabs(latestTabs)
      setTabsUpdated(Date.now())
    },
    [getTabs]
  )

  useEffect(() => {
    handlePageLoad(location.pathname)
  }, [location, handlePageLoad])

  useEffect(() => {
    handlePageLoad(location.pathname)
  }, [handlePageLoad, location.pathname])

  useEffect(() => {}, [tabs])

  return (
    <Fragment>
      {org && (
        <OrgPage
          theme={theme || undefined}
          org={org}
          tabs={[{ name: 'Settings' }]}
        >
          <PageSection>
            <Fragment>
              <PageHeading title="Settings"></PageHeading>
              {tabsUpdated && (
                <TabbedMenu
                  tabs={tabs}
                  currentPage={currentPage}
                  org={org}
                ></TabbedMenu>
              )}

              {currentPage !== null && currentPage === '' && (
                <SettingsTabGeneral></SettingsTabGeneral>
              )}
              {currentPage !== null && currentPage === 'branding' && (
                <SettingsTabBranding></SettingsTabBranding>
              )}
              {currentPage !== null && currentPage === 'apikey' && (
                <SettingsTabApikey></SettingsTabApikey>
              )}
              {currentPage !== null && currentPage === 'billing' && (
                <Elements stripe={stripePromise} options={stripeOptions}>
                  <SettingsTabBilling></SettingsTabBilling>
                </Elements>
              )}
              {currentPage === null && <div>Unknown page</div>}
            </Fragment>
          </PageSection>
        </OrgPage>
      )}
    </Fragment>
  )
}
