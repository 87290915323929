import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { GenderTypes } from '../../../lib/types'
import {
  dateInThePast,
  getPrettyDate,
  refreshCurrentUser,
} from '../../../lib/utils'
import { Button } from '../../form/button/Button'
import SimpleSelectField from '../../form/SimpleSelectField'
import TextInputField, { onChangeInterface } from '../../form/TextInputField'
import { UserImage } from '../../layout/UserImage'
import { SettingsAccountProps } from './SettingsTabAccount'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import PasswordStength from '../../form/PasswordStrength'
import { ProfileAddress, ProfileSchema } from '../../../lib/validators'
import { authFetch } from '../../../providers/AuthProvider'
import toast from 'react-hot-toast'
// import { ComingSoon } from "../../ui/ComingSoon";
import { z } from 'zod'
import { GraphQLResponseSchema } from '../../../lib/errors'
import { ImageUploaderAndCropper } from '../../form/ImageUploaderAndCropper'
import { acceptedImageFiles } from '../../form/FileDrop'
import {
  ImageUpload,
  ImageUploadResultWithBlob,
  ImageWithCrop,
} from '../../../lib/interfaces'
import posthog from '../../../lib/posthog'
import { ComingSoon } from '../../ui/ComingSoon'
import { GroupSection, GroupSectionItem } from '../../layout/GroupSection'

interface SettingsProfileFormData {
  firstName?: string
  lastName?: string
  email?: string
  gender?: 'male' | 'female'
  dob?: string
  address?: ProfileAddress
  phone?: string
  image?: string | null
  uuid?: string
  password?: string
}

export const UserUpdateSchema = z.object({
  uuid: z.string().uuid(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  image: z.string().url().optional().nullable(),
})
export type UserUpdate = z.infer<typeof UserUpdateSchema>

// need z zod type here

export default function SettingsTabAccountProfile({
  user,
  profile,
}: SettingsAccountProps) {
  const [open, setOpen] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [defaultEmail, setDefaultEmail] = useState('')
  const [defaultState, setDefaultState] = useState<SettingsProfileFormData>({})
  const cancelButtonRef = useRef<HTMLButtonElement>(null)
  const passwordConfirmationRef = useRef<HTMLInputElement>(null)
  const [formData, setFormData] = useState<SettingsProfileFormData>({})
  const [initComplete, setInitComplete] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [avatar, setAvatar] = useState<string | null>(null)
  const [userImage, setUserImage] = useState<ImageUpload>()
  const [imageWithCrop, setImageWithCrop] = useState<ImageWithCrop | null>()

  const handleElementChange = (e: onChangeInterface) => {
    setFormData((prev: SettingsProfileFormData) => ({
      ...prev,
      [e.name]: e.value,
    }))
  }

  const getAddress = useCallback((): ProfileAddress | undefined => {
    if (!profile) {
      return
    }
    if (!profile.address) {
      return
    }
    if (formData.address) {
      return formData.address
    }
    return profile.address
  }, [profile, formData.address])

  const getPrettyAddress = useCallback(
    (add?: ProfileAddress): string => {
      const address = add ? add : getAddress()
      if (!address) {
        return ''
      }
      const line2 = address.addressLine2 || ''
      return `${address.addressLine1} ${line2}, ${address.city} ${
        address.state
      } ${address.postCode || ''}`
    },
    [getAddress]
  )

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    console.log('form data to submit', formData)

    // setOpen(true);
    if (formData.email && formData.email !== defaultEmail) {
      // if the email changes, we n
      setOpen(true)
    } else {
      await updateProfile()
    }
  }

  const handleConfirmationDialog = async (): Promise<void> => {
    console.log('we hit the okay button... lets submit now')
    if (
      passwordConfirmationRef.current &&
      passwordConfirmationRef.current.value
    ) {
      formData.password = passwordConfirmationRef.current.value
    } else {
      toast.error('Please enter your password', {
        duration: 3000,
      })
      return
    }
    const didSucceed = await updateProfile()
    if (didSucceed) {
      delete formData.password
      console.log('did it succeed?', didSucceed)
      setOpen(false)
    }
  }

  const updateProfile = async (): Promise<boolean> => {
    const toastId = toast.loading('Saving Profile', {
      duration: 3000,
    })

    let img: string | undefined = undefined
    let rawImg: string | undefined = undefined
    // upload the image
    if (userImage) {
      console.log('we have a user image set, we should upload it', userImage)
      const rawImage = uploadFile({
        name: `${userImage.name}Raw`,
        url: userImage.url || '',
        blob: userImage.originalFile,
        filename: `raw-${userImage.originalFilename}`,
      })
      const normalimage = uploadFile({
        name: userImage.name,
        url: userImage.url || '',
        blob: userImage.croppedFile,
        filename: `${userImage.filename}`,
      })

      const images = await Promise.all([rawImage, normalimage])
      if (images && images.length === 2) {
        img = images[1]?.url
        rawImg = images[0]?.url
      }
    }

    formData.uuid = profile.uuid
    setIsSaving(true)
    const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        query: `
          mutation update($profileUuid: UUID!, $profileData:UpdateProfileInput!, $userData:UpdateUserInput!) {
            updateProfile(profileUuid: $profileUuid data: $profileData) {
              uuid
              dob
              gender
              phone
              address {
                addressLine1
                addressLine2
                city
                state
                postCode
                country
              }
            }
            updateUser(data: $userData) {
              uuid
              firstName
              lastName
              email
            }
          }
        `,
        variables: {
          profileData: {
            dob: formData.dob,
            gender: formData.gender,
            address: formData.address,
            phone: formData.phone,
            uuid: formData.uuid,
            firstName: formData.firstName,
            lastName: formData.lastName,
          },
          userData: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            password: formData.password,
            image: img,
            imageCrop: {
              crop: userImage?.crop,
              url: rawImg,
            },
          },
          profileUuid: profile.uuid,
        },
      }),
    })

    setIsSaving(false)

    const response = result.data
    try {
      // parse the base object first
      const envelope = GraphQLResponseSchema.parse(response)
      if (envelope.errors && envelope.errors.length > 0) {
        throw new Error(envelope.errors[0].message)
      }
      const parsedData = ProfileSchema.parse(response.data.updateProfile)
      const userData = UserUpdateSchema.parse(response.data.updateUser)
      setFormData((prev) => ({
        ...prev,
        ...parsedData,
        ...userData,
        dob: parsedData.dob ? getPrettyDate(parsedData.dob) : '',
      }))
      setDefaultState((prev) => ({
        ...prev,
        ...parsedData,
        ...userData,
        dob: parsedData.dob ? getPrettyDate(parsedData.dob) : '',
      }))

      await refreshCurrentUser()
      // setDefaultEmail(parsedData.)
      toast.success('Profile saved', {
        duration: 3000,
        id: toastId,
      })
      return true
    } catch (e) {
      console.log(e)
      let message = 'Failed to save profile'
      if (e instanceof Error) {
        message = e.message
      }
      toast.error(message, {
        duration: 3000,
        id: toastId,
      })
      return false
    }
  }

  const canSubmitForm = useCallback((): boolean => {
    let hasChanged = false

    if (formData.firstName && formData.firstName !== defaultState.firstName) {
      hasChanged = true
    }
    if (formData.lastName && formData.lastName !== defaultState.lastName) {
      hasChanged = true
    }
    if (formData.dob && formData.dob !== defaultState.dob) {
      hasChanged = true
    }
    if (formData.gender && formData.gender !== defaultState.gender) {
      hasChanged = true
    }
    if (formData.email && formData.email !== defaultState.email) {
      hasChanged = true
    }
    if (formData.phone && formData.phone !== defaultState.phone) {
      hasChanged = true
    }
    if (imageWithCrop?.filename) {
      hasChanged = true
    }
    if (
      formData.address &&
      getPrettyAddress(formData.address) !==
        getPrettyAddress(defaultState.address)
    ) {
      hasChanged = true
    }

    return hasChanged
  }, [defaultState, formData, getPrettyAddress])

  useEffect(() => {
    const hasChanged = canSubmitForm()
    setCanSubmit(hasChanged)
  }, [formData, canSubmitForm])

  useEffect(() => {
    if (user && profile && !initComplete) {
      setInitComplete(true)
      setDefaultEmail(user.email)
      const state: SettingsProfileFormData = {
        address: getAddress(),
        dob: getPrettyDate(profile.dob),
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        gender: profile.gender,
        phone: profile.phone ? profile.phone : undefined,
        image: user.image || undefined,
      }
      setDefaultState(state)
      setFormData(state)
      setAvatar(user.image || null)
    }
  }, [user, profile, getAddress, initComplete])

  useEffect(() => {
    if (user) {
      if (user.imageCrop) setImageWithCrop(user.imageCrop)
      if (!user.imageCrop) {
        setImageWithCrop({
          url: user.image,
          filename: user.image,
        })
      }
    }
  }, [user])

  const uploadFile = async (
    item: ImageUploadResultWithBlob
  ): Promise<ImageUploadResultWithBlob | undefined> => {
    const payload = new FormData()
    payload.append('file', item.blob)
    payload.append('name', item.name)
    payload.append('userUuid', user.uuid)
    payload.append('filename', item.filename)
    // update its status
    // item.status = UploadStatus.Uploading;
    // setUploads((prev) => ({
    //   ...prev,
    //   [item.file.name]: item,
    // }));
    const result = await authFetch(
      `${import.meta.env.VITE_API_URL}/user/upload`,
      {
        method: 'POST',
        headers: {
          'content-type': 'multipart/form-data',
        },
        data: payload,
        onUploadProgress: (progressEvent) => {
          if (progressEvent && progressEvent.total) {
            // const percentCompleted = Math.round(
            //   (progressEvent.loaded * 100) / progressEvent.total
            // )
            // item.uploadProgress = 100 - percentCompleted;
            // setUploads((prev) => ({
            //   ...prev,
            //   [item.file.name]: item,
            // }));
          }
        },
      }
    )
    if (result.data.error) {
      return undefined
    }
    return {
      blob: item.blob,
      filename: item.filename,
      url: result.data.url,
      name: item.name,
    }
  }

  return (
    <GroupSection title="Personal Information">
      <GroupSectionItem>
        <form className="md:col-span-2" method="POST" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <div className="col-span-full flex items-center gap-x-8">
              <UserImage size="lg" image={avatar}></UserImage>
              {posthog.isFeatureEnabled('can-update-avatar') && (
                <ImageUploaderAndCropper
                  acceptedFileTypes={acceptedImageFiles}
                  show={open}
                  originalImageWithCrop={imageWithCrop || undefined}
                  onClose={() => setOpen(false)}
                  desiredFilename="avatar"
                  aspectRatio={1}
                  name="avatar"
                  circularCrop={true}
                  onImageSelected={async (data) => {
                    // lets try to upload this image
                    setUserImage({
                      crop: data.crop,
                      croppedFile: data.blob,
                      originalFile: data.rawFile,
                      originalFilename: data.rawFileFilename,
                      name: data.name,
                      filename: data.filename,
                    })

                    setAvatar(data.url || null)
                    setImageWithCrop({
                      crop: data.crop,
                      url: data.rawUrl,
                      filename: data.rawFileFilename,
                    })

                    setFormData((prev: SettingsProfileFormData) => ({
                      ...prev,
                      image: data.url,
                      imageCrop: data.crop,
                    }))
                  }}
                ></ImageUploaderAndCropper>
              )}
              {!posthog.isFeatureEnabled('can-update-avatar') && (
                <ComingSoon title="Avatar customisation coming soon">
                  <Button type="button" label="Change avatar"></Button>
                  <p className="mt-2 text-xs leading-5 text-gray-dark">
                    JPG, GIF or PNG. 1MB max.
                  </p>
                </ComingSoon>
              )}
            </div>

            <div className="sm:col-span-3">
              <TextInputField
                name="firstName"
                id="first-name"
                type="text"
                label="First name"
                value={formData.firstName || ''}
                required={true}
                handleChange={handleElementChange}
              ></TextInputField>
            </div>

            <div className="sm:col-span-3">
              <TextInputField
                name="lastName"
                id="last-name"
                type="text"
                label="Last name"
                value={formData.lastName || ''}
                required={true}
                handleChange={handleElementChange}
              ></TextInputField>
            </div>

            <div className="sm:col-span-3">
              <SimpleSelectField
                handleChange={handleElementChange}
                label="Gender"
                name="gender"
                value={formData.gender}
                required={true}
                firstItemText="-- Please select an option --"
                // disabled={saving || loading}
                options={[
                  {
                    label: 'Male',
                    value: GenderTypes.Male,
                  },
                  {
                    label: 'Female',
                    value: GenderTypes.Female,
                  },
                ]}
              ></SimpleSelectField>
            </div>

            <div className="sm:col-span-3">
              <TextInputField
                id="date"
                label="Date of Birth"
                name="dob"
                type="date"
                min={dateInThePast(130)}
                max={dateInThePast(18)}
                value={
                  formData.dob ? getPrettyDate(new Date(formData.dob)) : ''
                }
                placeholder="Select your date of birth, you must be over 18 years of age."
                // disabled={saving || loading}
                handleChange={handleElementChange}
              ></TextInputField>
            </div>
            <div className="col-span-full">
              <TextInputField
                name="address"
                id="address"
                type="address"
                label="Street Address"
                value={getPrettyAddress() || ''}
                handleChange={handleElementChange}
              ></TextInputField>
            </div>

            <div className="sm:col-span-3">
              <TextInputField
                name="email"
                id="email"
                type="email"
                label="Email Address"
                value={formData.email || ''}
                // readonly={true}
                // className="text-gray-dark cursor-not-allowed"
                handleChange={handleElementChange}
              ></TextInputField>
            </div>
            <div className="sm:col-span-3">
              <TextInputField
                name="phone"
                id="phone"
                type="tel"
                label="Phone Number"
                value={formData.phone || ''}
                handleChange={handleElementChange}
              ></TextInputField>
            </div>
          </div>

          <div className="mt-8 flex">
            <Button type="submit" disabled={!canSubmit} label="Save"></Button>
          </div>
        </form>
      </GroupSectionItem>

      {/* modal code here */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-orange-500"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Wait a minute...
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500 mb-2">
                            <b>We take privacy and security seriously. </b>You
                            are updating your personal information, and we just
                            want to make sure its you.
                          </p>
                          <p className="text-sm text-gray-500">
                            Please enter your password below to continue.
                          </p>
                        </div>
                        <div className="mt-2">
                          <PasswordStength
                            id="password"
                            name="password"
                            placeholder="Enter your current password"
                            hideBars={true}
                            segments={5}
                            required={true}
                            reference={passwordConfirmationRef}
                            segmentColours={[
                              'bg-bgt-primary',
                              'bg-bgt-primary/80',
                              'bg-bgt-primary/60',
                              'bg-jade/60',
                              'bg-jade',
                            ]}
                          ></PasswordStength>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse sm:px-6">
                    <Button
                      label="Save personal information"
                      type="button"
                      color="jade"
                      onClick={() => handleConfirmationDialog()}
                      disabled={isSaving}
                    ></Button>
                    <Button
                      label="Cancel"
                      type="button"
                      classNames="mr-2"
                      onClick={() => setOpen(false)}
                      reference={cancelButtonRef}
                      loading={isSaving}
                      disabled={isSaving}
                    ></Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </GroupSection>
  )
}
