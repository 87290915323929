import { Fragment, useEffect, useState } from 'react'
import { GroupedBiomarkers } from '../../../lib/validators'

import BiomarkerResultRow from '../../biomarker/BiomarkerResultRow'
import RoundedSection from '../../layout/RoundedSection'
import { ResultsBiomarkerProps } from '../../../lib/interfaces'

export const ResultTabBiomarkers: React.FC<ResultsBiomarkerProps> = ({
  groupedBiomarkers,
}) => {
  const [groupsOfBiomarkers, setGroupsOfBiomarkers] =
    useState<GroupedBiomarkers>()

  useEffect(() => {
    setGroupsOfBiomarkers(groupedBiomarkers)
  }, [])

  return (
    <Fragment>
      {!groupsOfBiomarkers && <div>Loading...</div>}
      {groupsOfBiomarkers && groupsOfBiomarkers.length > 0 && (
        <Fragment>
          {groupsOfBiomarkers.map((group) => (
            <RoundedSection
              size="lg"
              className="mb-8"
              key={`${group.category.name}-section`}
            >
              <h2 className="text-lg font-semibold text-black/70 dark:text-white mb-2 select-none">
                {group.category.name}
              </h2>
              <div className="text-sm text-black/70 dark:text-gray-light mb-6 mt-4 select-none">
                {group.category.description}
              </div>
              {group.biomarkers.map((biomarker) => (
                <div className="rounded-md" key={biomarker.id}>
                  <BiomarkerResultRow
                    key={`${biomarker.id}-biomarker-row`}
                    // className="mt-4"
                    biomarker={biomarker}
                  ></BiomarkerResultRow>
                  <hr className="border border-gray mt-8 mb-8 dark:border-dark-gray-light"></hr>
                </div>
              ))}
              <div className="mb-4"></div>
            </RoundedSection>
          ))}
        </Fragment>
      )}
    </Fragment>
  )
}
