import { proxy, useSnapshot } from 'valtio'
import { BillingCurrent, Organisation, User } from './validators'
import { ThemeInterface } from './interfaces'

interface Store {
  user: User | null
  organisations: Organisation[] | null
  currentOrganisation: string | null
  currentPage: string
  missingProfileData: string[] | null
  emailVerified: boolean | null
  profileComplete: boolean
  currentBilling?: BillingCurrent | null
  tmpTheme: ThemeInterface | null
  refreshedOrg: number
}

export const store = proxy<Store>({
  user: null,
  organisations: null,
  currentOrganisation: null,
  currentPage: '',
  missingProfileData: [],
  emailVerified: null,
  profileComplete: false,
  currentBilling: undefined,
  tmpTheme: null,
  refreshedOrg: 1,
})

export const actions = {
  setUser(user: User) {
    store.user = user
  },
  setOrganisations(orgs: Organisation[]) {
    store.organisations = orgs
    actions.setRefreshedOrg()
  },
  setCurrentOrganisation(org: string | null) {
    if (org) {
      localStorage.setItem('currentOrg', org)
      actions.setCurrentBilling(undefined)
    } else {
      localStorage.removeItem('currentOrg')
    }
    actions.setCurrentBilling(undefined)
    store.currentOrganisation = org
    store.tmpTheme = null
  },
  setTemporaryOrganisationTheme(theme: ThemeInterface) {
    store.tmpTheme = theme
  },

  updateCurrentOrganisation() {
    const activeOrg = localStorage.getItem('currentOrg')
    store.tmpTheme = null
    store.currentOrganisation = activeOrg
  },
  setMissingProfileData(missingData: string[]) {
    store.missingProfileData = missingData
  },
  setCurrentPage(page: string) {
    store.currentPage = page
  },
  setEmailVerified(verified: boolean) {
    store.emailVerified = verified
  },
  setIsProfileComplete(complete: boolean) {
    store.profileComplete = complete
  },

  setCurrentBilling(billing: BillingCurrent | null | undefined) {
    store.currentBilling = billing
  },
  setRefreshedOrg() {
    store.refreshedOrg += 1
  },
}
export function useUser(): User | null {
  return useSnapshot(store).user
}
export function useOrganisations(): readonly Organisation[] | null {
  return useSnapshot(store).organisations
}
export function useCurrentOrganisation(): string | null {
  return useSnapshot(store).currentOrganisation
}
export function useTheme(): ThemeInterface | null {
  let t: ThemeInterface | null = null
  if (store.organisations) {
    for (const i in store.organisations) {
      const org = store.organisations[i]
      if (org.id === store.currentOrganisation) {
        t = org.theme || null
      }
    }
  }
  return useSnapshot(store).tmpTheme || t
}
export function useOrganisation(): Organisation | null {
  let t: Organisation | null = null
  if (store.organisations) {
    for (const i in store.organisations) {
      const org = store.organisations[i]
      if (org.id === store.currentOrganisation) {
        t = org || null
      }
    }
  }
  return t
}

export function useMissingProfileData(): readonly string[] | null {
  return useSnapshot(store).missingProfileData
}

export function useIsProfileComplete(): boolean {
  return useSnapshot(store).profileComplete
}

export function useEmailVerified(): boolean | null {
  return useSnapshot(store).emailVerified
}

export function useCurrentPage() {
  return useSnapshot(store).currentPage
}

export function useCurrentBilling() {
  return useSnapshot(store).currentBilling
}
export function useRefreshedOrgTrigger() {
  return useSnapshot(store).refreshedOrg
}
