import { Fragment, useEffect, useState } from 'react'
import {
  File,
  FileListSchema,
  ReferralResult,
  ResultReferral,
} from '../../../lib/validators'

import { useUser } from '../../../lib/store'
import { authFetch } from '../../../providers/AuthProvider'

import {
  downloadFile,
  getNiceDate,
  humanFileSize,
  months,
} from '../../../lib/utils'
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import { ResultsTabProps } from '../../../lib/interfaces'

interface FileGroups {
  referralFiles: File[]
  resultFiles: File[]
}

export const ResultTabAttachments: React.FC<ResultsTabProps> = ({
  resultReferral,
  profile,
  org,
}) => {
  // export function ResultTabOverview({ referralResult }: ResultsTabProps) {
  const user = useUser()
  const [result, setResult] = useState<ResultReferral>()
  const [attachments, setAttachments] = useState<FileGroups>()
  // const [attachments, setAttachments] = useState<FileGroups>();

  useEffect(() => {
    setResult(resultReferral)
  }, [])

  useEffect(() => {
    // set the profile to be the first one
    if (profile && result) {
      getResultAttachments()
    }
    // set default profile to be the first one

    // fetch the tests here
  }, [user, profile, result])

  const getResultAttachments = async (): Promise<ReferralResult> => {
    // setTimeout(async () => {
    if (!user) {
      throw new Error('Missing user')
    }
    if (!result) {
      throw new Error('Missing result')
    }

    const response = await authFetch(
      `${import.meta.env.VITE_API_URL}/graphql`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        data: JSON.stringify({
          query: `{
          filesForResult(uuid: "${result.uuid}" profileUuid: "${profile}" ${org ? `organisationUuid: "${org.uuid}"` : ''}) {
            uuid
            filename
            extension
            filesize
            createdAt
          }}`,
        }),
      }
    )
    const data = response.data
    // console.log("got the data", data);
    const resultFiles = FileListSchema.parse(data.data.filesForResult)
    // const referralFiles = FileListSchema.parse(data.data.filesForReferral);
    setAttachments({
      referralFiles: [],
      resultFiles,
    })
    // const parsedData = ReferralResultSchema.parse(data.data.referral);
    // setReferral(parsedData);
    return data
  }

  // useEffect(() => {
  //   if (attachments) {
  //     console.log("the attachments", attachments);
  //   }
  // }, [attachments]);

  return (
    <Fragment>
      {!result && <div>Loading...</div>}
      {result && (
        <Fragment>
          {!attachments && <div>loading</div>}
          {attachments &&
            attachments.resultFiles &&
            attachments.resultFiles.length > 0 && (
              <Fragment>
                <div className="grid grid-cols-3 sm:grid-cols-8">
                  {/* <GridTableHeader className="col-span-3 sm:col-span-8">
                Pathology Report
              </GridTableHeader> */}
                  <div
                    className={`bg-gray-light dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm col-span-3 sm:col-span-4 px-2 py-1 sm:p-3 sm:px-4`}
                  >
                    Pathology Report(s)
                  </div>
                  <div
                    className={`bg-gray-light dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm col-span-1 sm:col-span-1 hidden sm:block px-2 py-1 sm:p-3`}
                  >
                    Filesize
                  </div>
                  <div
                    className={`bg-gray-light dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm col-span-2 sm:col-span-2 px-2 py-1 sm:p-3 hidden sm:block`}
                  >
                    Created Date
                  </div>
                  <div
                    className={`bg-gray-light dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm col-span-1 sm:col-span-1 text-right px-2 py-1 sm:p-3  hidden sm:block`}
                  >
                    Download
                  </div>
                </div>
                <div className="grid grid-cols-3 sm:grid-cols-8">
                  {Object.values(attachments.resultFiles).map((item, k) => (
                    <Fragment key={`result-file-attachment-${k}`}>
                      <div
                        className={`px-2 py-1 sm:p-3 sm:px-4 col-span-3 sm:col-span-4 text-sm text-black dark:text-white ${
                          k % 2 === 1
                            ? 'bg-gray-light dark:bg-dark-gray-light'
                            : ''
                        } rounded-tl-lg rounded-bl-lg`}
                      >
                        {item.filename}{' '}
                        <span className="inline-block sm:hidden text-xs text-gray-semi-dark dark:text-gray-light">
                          ({humanFileSize(item.filesize)})
                        </span>
                      </div>
                      <div
                        className={`hidden sm:block px-2 py-1 sm:p-3 text-sm col-span-1 sm:col-span-1 text-gray-semi-dark dark:text-gray-light ${
                          k % 2 === 1
                            ? 'bg-gray-light dark:bg-dark-gray-light'
                            : ''
                        }`}
                      >
                        {humanFileSize(item.filesize)}
                      </div>
                      <div
                        className={`px-2 py-1 sm:p-3 text-sm col-span-2 sm:col-span-2 text-gray-semi-dark dark:text-gray-light ${
                          k % 2 === 1
                            ? 'bg-gray-light dark:bg-dark-gray-light'
                            : ''
                        }`}
                      >
                        {getNiceDate(item.createdAt.getDate())}{' '}
                        {months[item.createdAt.getMonth()]}{' '}
                        {item.createdAt.getFullYear()}
                      </div>
                      <div
                        className={`px-2 py-1 sm:p-3 col-span-1 sm:col-span-1 text-sm text-gray-dark text-right ${
                          k % 2 === 1
                            ? 'bg-gray-light dark:bg-dark-gray-light'
                            : ''
                        } rounded-br-lg rounded-tr-lg`}
                      >
                        <button
                          className="cursor-pointer"
                          title="Download Referral"
                          onClick={() =>
                            downloadFile(user, item.uuid, profile, org?.uuid)
                          }
                        >
                          <ArrowDownTrayIcon
                            width={20}
                            className="fill-black hover:fill-bgt-primary dark:fill-white dark:hover:fill-bgt-primary"
                          ></ArrowDownTrayIcon>
                        </button>
                      </div>
                    </Fragment>
                  ))}
                </div>
              </Fragment>
            )}
          {attachments &&
            attachments.referralFiles &&
            attachments.referralFiles.length > 0 && (
              <div className="grid grid-cols-3 sm:grid-cols-8 mt-10">
                {/* <GridTableHeader className="col-span-3 sm:col-span-8">
                Pathology Report
              </GridTableHeader> */}
                <div
                  className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm col-span-3 sm:col-span-4 px-2 py-1 sm:p-3 sm:px-4`}
                >
                  Referral
                </div>
                <div
                  className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm col-span-1 sm:col-span-1 hidden sm:block px-2 py-1 sm:p-3`}
                >
                  Filesize
                </div>
                <div
                  className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm col-span-2 sm:col-span-2 px-2 py-1 sm:p-3 hidden sm:block`}
                >
                  Created Date
                </div>
                <div
                  className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm col-span-1 sm:col-span-1 text-right px-2 py-1 sm:p-3  hidden sm:block`}
                >
                  Download
                </div>
                {Object.values(attachments.referralFiles).map((item, k) => (
                  <Fragment key={`referral-file-attachment-${k}`}>
                    <div
                      className={`px-2 py-1 sm:p-3 sm:px-4 col-span-3 sm:col-span-4 text-sm text-black ${
                        k % 2 === 1 ? 'bg-gray-light' : ''
                      } rounded-tl-lg rounded-bl-lg`}
                    >
                      {item.filename}{' '}
                      <span className="inline-block sm:hidden text-xs text-gray-semi-dark">
                        ({humanFileSize(item.filesize)})
                      </span>
                    </div>
                    <div
                      className={`hidden sm:block px-2 py-1 sm:p-3 text-sm col-span-1 sm:col-span-1 text-gray-semi-dark ${
                        k % 2 === 1 ? 'bg-gray-light' : ''
                      }`}
                    >
                      {humanFileSize(item.filesize)}
                    </div>
                    <div
                      className={`px-2 py-1 sm:p-3 text-sm col-span-2 sm:col-span-2 text-gray-semi-dark ${
                        k % 2 === 1 ? 'bg-gray-light' : ''
                      }`}
                    >
                      {getNiceDate(item.createdAt.getDate())}{' '}
                      {months[item.createdAt.getMonth()]}{' '}
                      {item.createdAt.getFullYear()}
                    </div>
                    <div
                      className={`px-2 py-1 sm:p-3 col-span-1 sm:col-span-1 text-sm text-gray-dark text-right ${
                        k % 2 === 1 ? 'bg-gray-light' : ''
                      } rounded-br-lg rounded-tr-lg`}
                    >
                      <button
                        className="cursor-pointer"
                        title="Download Referral"
                        onClick={() => downloadFile(user, item.uuid, profile)}
                      >
                        <ArrowDownTrayIcon
                          width={20}
                          className="fill-black hover:fill-bgt-primary"
                        ></ArrowDownTrayIcon>
                      </button>
                    </div>
                  </Fragment>
                ))}
              </div>
            )}
        </Fragment>
      )}
    </Fragment>
  )
}
