import { Fragment, useEffect, useState } from 'react'
import { ThemeInterface } from '../../../../../lib/interfaces'
import { OrganisationWithBranding } from '../../../../context/OrganisationContext'
import { useNavigate } from 'react-router-dom'
import { sendPackageToUser } from '../../../../../lib/organisation'
import toast from 'react-hot-toast'
import { Modal } from '../../../../layout/Modal'
import { getBundleUrl } from '../../../../../lib/routes'
import TextInputField from '../../../../form/TextInputField'
import { Textarea } from '../../../../form/Textarea'
import { InfoSection } from '../../../../ui/InfoSection'

interface SendPackageModalProps {
  show: boolean
  productId?: string
  productName?: string
  theme?: ThemeInterface
  setShow: (openState: boolean) => void
  org: OrganisationWithBranding | null
  dismissOnSkip?: boolean
  dismissOnSend?: boolean
}

export function SendPackageModal({
  show,
  setShow,
  theme,
  productId,
  org,
  dismissOnSkip = false,
  dismissOnSend = false,
}: SendPackageModalProps) {
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState<NameEmailAndNote>({})
  const [canSubmit, setCanSubmit] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const isEmailValid = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi
    )

    setCanSubmit(
      formData.name && formData.email && isEmailValid.exec(formData.email)
        ? true
        : false
    )
  }, [formData])

  useEffect(() => {
    setOpen(show)
  }, [show])

  const sendEmailToUser = async () => {
    // send the meail here
    // packageUrl

    if (!org) {
      setError('Missing organisation, unable to save')
      return
    }
    if (!productId) {
      setError('Missing product id, unable to save')
      return
    }
    const sendEmailResponse = await sendPackageToUser(
      org.uuid,
      productId,
      formData
    )
    if (sendEmailResponse.length > 0) {
      if (sendEmailResponse[0].status !== 'success') {
        setError(
          `Unable to email test to ${sendEmailResponse[0].name}, please try again.`
        )

        return
      }
      setShow(false)
      toast.success(`Package sent to ${sendEmailResponse[0].name}`, {
        duration: 3000,
      })
      return
    }
    setError('Invalid response from server')
  }
  return (
    <Modal
      show={open}
      error={error}
      options={{
        mainActions: [
          {
            label: 'Send',
            handler: async () => {
              // lets submit the form now.
              setLoading(true)
              console.log('do some long task here...')
              await sendEmailToUser()
              setLoading(false)

              if (dismissOnSend) {
                setShow(false)
                return
              }
              if (!org || !org.uuid) {
                navigate('/')
              } else {
                navigate(getBundleUrl(org?.id, productId!))
              }
            },
            disabled: !canSubmit,
            loading: loading,
          },
        ],
        secondaryAction: {
          label: 'Skip',

          handler: () => {
            console.log('skip...')
            if (dismissOnSkip) {
              setShow(false)
              return
            }
            if (!org || !org.uuid) {
              navigate('/')
            } else {
              navigate(getBundleUrl(org?.id, productId!))
            }
            setShow(false)
          },
        },
        title: 'Send to...',
        description: (
          <>
            Send this bundle for someone to purchase.
            <InfoSection>
              Simply enter the recipients name, email address, and an optional
              comment and we will send a purchase link directly to them via
              email.
            </InfoSection>
          </>
        ),
        content: (
          <div className="mt-2">
            <NameEmailNoteInput onChange={setFormData}></NameEmailNoteInput>
          </div>
        ),
      }}
      onClose={() => setShow(false)}
      theme={theme || undefined}
      size="lg"
    ></Modal>
  )
}

export interface NameEmailAndNote {
  name?: string
  email?: string
  note?: string
}
interface NameAndEmailWithNoteInputProps {
  onChange: (data: NameEmailAndNote) => void
}

function NameEmailNoteInput({ onChange }: NameAndEmailWithNoteInputProps) {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [note, setNote] = useState<string>('')

  useEffect(() => {
    onChange({
      name,
      email,
      note,
    })
  }, [name, email, note, onChange])

  return (
    <Fragment>
      <div className="grid grid-cols-4 gap-2">
        <div className="col-span-2">
          <TextInputField
            id="package-toname"
            name="package-toname"
            type="text"
            label="Recipient Name"
            value={name}
            handleChange={(d) => setName(d.strValue)}
          ></TextInputField>
        </div>
        <div className="col-span-2">
          <TextInputField
            id="package-toemail"
            name="package-tonemail"
            type="email"
            label="Recipient Email"
            value={email}
            handleChange={(d) => setEmail(d.strValue)}
          ></TextInputField>
        </div>
        <div className="col-span-4">
          <Textarea
            id="package-tonote"
            name="package-tonote"
            label="Comment (Optional)"
            value={note}
            handleChange={(d) => setNote(d.strValue)}
          ></Textarea>
        </div>
      </div>
    </Fragment>
  )
}
