import { Fragment, PropsWithChildren } from 'react'

interface ResultInfoSection extends PropsWithChildren {
  title: string
}

export default function ResultInfoSection({
  title,
  children,
}: ResultInfoSection) {
  return (
    <Fragment>
      <div className="text-md font-bold pb-1 select-none dark:text-gray-dark">
        {title}
      </div>
      <p
        dangerouslySetInnerHTML={{
          __html: children?.toString().replace(/\n/gi, '<br />') || '',
        }}
        className="pb-10 text-sm text-black/50 dark:text-off-white select-none"
      >
        {/* {children} */}
      </p>
    </Fragment>
  )
}
