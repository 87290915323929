import {
  organisationCreateTestUrl,
  organisationHomeUrl,
  organisationResultUrl,
  organisationResultsUrl,
  organisationSettingsUrl,
  organisationTestBundleUrl,
  organisationTestBundlesUrl,
  routes,
} from '../../lib/routes'

import {
  BeakerIcon,
  ClipboardDocumentIcon,
  Cog6ToothIcon,
  HomeIcon,
} from '@heroicons/react/24/solid'
import { MenuItem } from './MenuItems'

// export interface MenuItem {
//   name: string | ReactNode
//   href: string
//   target?: string
//   icon: React.ForwardRefExoticComponent<
//     Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
//       title?: string | undefined
//       titleId?: string | undefined
//     } & React.RefAttributes<SVGSVGElement>
//   >
//   current: boolean
// }

// export interface MenuItemGroup {
//   header: string | ReactNode
//   items: MenuItem[]
// }

export function organisationSettingsMenuItems(
  currentPage: string,
  urlPrefix: string
): MenuItem[] {
  return [
    {
      name: 'Settings',
      href: `${urlPrefix}/settings`,
      icon: Cog6ToothIcon,
      current:
        [routes[organisationSettingsUrl].name].indexOf(currentPage) !== -1, // TODO THis is a hack, we should fix this.
      // currentPage === (routes[testsUrl].name || routes[resultUrl].name),
    },
  ]
}
export function organisationMenuItems(
  currentPage: string,
  urlPrefix: string
): MenuItem[] {
  return [
    {
      name: 'Dashboard',
      href: urlPrefix,
      icon: HomeIcon,
      current: currentPage === routes[organisationHomeUrl].name,
    },
    {
      name: 'Results',
      href: `${urlPrefix}/results`,
      icon: ClipboardDocumentIcon,
      current:
        [
          routes[organisationResultsUrl].name,
          routes[organisationResultUrl].name,
        ].indexOf(currentPage) !== -1,
    },
    {
      name: 'Tests',
      href: `${urlPrefix}/bundles`,
      icon: BeakerIcon,
      current:
        [
          routes[organisationTestBundlesUrl].name,
          routes[organisationTestBundleUrl].name,
          routes[organisationCreateTestUrl].name,
        ].indexOf(currentPage) !== -1, // TODO THis is a hack, we should fix this.
      // currentPage === (routes[testsUrl].name || routes[resultUrl].name),
    },
    // {
    //   name: 'Results',
    //   href: `${urlPrefix}/bundle2`,
    //   icon: ClipboardDocumentIcon,
    //   current: currentPage === routes[organisationHomeUrl].name,
    // },
    // {
    //   name: 'Clients',
    //   href: `${urlPrefix}/bundle2`,
    //   icon: UserIcon,
    //   current: currentPage === routes[organisationHomeUrl].name,
    // },
    // {
    //   name: 'Create Test Bundle',
    //   href: `${urlPrefix}/create-test`,
    //   icon: BeakerIcon,
    //   current:
    //     [routes[organisationCreateTestUrl].name].indexOf(currentPage) !== -1, // TODO THis is a hack, we should fix this.
    //   // currentPage === (routes[testsUrl].name || routes[resultUrl].name),
    // },
  ]
}
