import { Dialog, Transition } from '@headlessui/react'
import { Fragment, ReactNode, useEffect, useState } from 'react'
import { ThemeInterface } from '../../lib/interfaces'
import Puff from 'react-loading-icons/dist/esm/components/puff'
import { Button, ButtonColors } from '../form/button/Button'
import { useIntercom } from 'react-use-intercom'

interface ModalAction {
  label: string
  disabled?: boolean
  loading?: boolean
  handler?: () => void
  color?: ButtonColors
}

export interface ModalOptionsProps {
  title?: string | ReactNode
  mainActions: ModalAction[]
  secondaryAction?: ModalAction
  content: string | ReactNode
  description?: string | ReactNode
}

export type DialogSize = 'max' | '2xl' | 'xl' | 'lg' | 'md' | 'sm'

export interface ModalProps {
  show: boolean
  size?: DialogSize
  theme?: ThemeInterface
  options?: ModalOptionsProps
  loading?: boolean
  error?: string
  onClose?: () => void
  canDismiss?: boolean
}

export function Modal(props: ModalProps) {
  const intercom = useIntercom()
  const [open, setOpen] = useState(props.show)
  const [canDismissDialog, setCanDismissDialog] = useState<boolean>(
    props.canDismiss || true
  )
  const [isSaving, setIsSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dialogErrorMessage, setDialogErrorMessage] = useState<
    string | undefined
  >()

  //
  useEffect(() => {
    intercom.update({
      hideDefaultLauncher: props.show,
    })
    setOpen(props.show)
  }, [props, intercom])
  useEffect(() => {
    setDialogErrorMessage(props.error)
  }, [props.error])

  const closeDialog = () => {
    console.log('CLOSE DIALOG', canDismissDialog)
    if (isSaving || canDismissDialog === false) {
      return
    }
    intercom.update({
      hideDefaultLauncher: false,
    })
    // intercom.boot()
    setOpen(false)
    props.onClose && props.onClose()
  }

  useEffect(() => {
    setIsSaving(false)
    setCanDismissDialog(props.canDismiss === false ? false : true)
  }, [props.canDismiss, props])

  useEffect(() => {
    setLoading(props.loading || false)
  }, [props.loading])

  const getSize = (): string => {
    if (!props.size) return 'sm:max-w-sm'
    switch (props.size) {
      case 'max':
        return 'sm:max-w-[80dvw]'
      case '2xl':
        return 'sm:max-w-2xl'
      case 'xl':
        return 'sm:max-w-xl'
      case 'lg':
        return 'sm:max-w-lg'
      default:
        return 'sm:max-w-sm'
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-10 ${isSaving || loading ? 'cursor-progress' : ''}`}
        // initialFocus={cancelButtonRef}
        onClose={closeDialog}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-dark-gray-lighter dark:bg-opacity-75 backdrop-blur-sm bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div
          className={`fixed inset-0 z-10 w-screen overflow-y-auto ${
            isSaving || loading ? 'cursor-progress' : ''
          }`}
        >
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white dark:bg-dark-gray-light text-left shadow-xl transition-all sm:my-8 sm:w-full ${getSize()} ${
                  isSaving || loading ? 'cursor-progress' : ''
                }`}
              >
                {(isSaving || loading) && (
                  <div className="absolute w-full h-full bg-white/50 dark:bg-dark-gray-light/50 z-50 flex justify-center items-center ">
                    <Puff
                      height={100}
                      width={100}
                      speed={0.8}
                      stroke="#FF3A5F"
                      fill="#FF3A5F"
                    />
                  </div>
                )}
                <div className="bg-white dark:bg-dark-gray-light  px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  {/* <div className="sm:flex sm:items-center"> */}
                  <div className="">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-semibold leading-6 text-gray-900 dark:text-white "
                      >
                        {props.options?.title || ''}
                      </Dialog.Title>
                      {props.options?.description && (
                        <div className="mt-4 mb-4 text-md  dark:text-white">
                          {props.options?.description}
                        </div>
                      )}
                      <div className="mt-2 dark:text-white">
                        {props.options?.content}
                        {dialogErrorMessage && (
                          <div className="text-blood font-semibold text-sm p-4 border border-blood rounded-md bg-blood/5">
                            ⚠️ {dialogErrorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse sm:px-6 items-stretch dark:bg-dark-gray-lighterer">
                  {props.options?.mainActions.map((action, idx) => (
                    <Button
                      key={`dialog-main-action-${idx}`}
                      classNames="ml-2"
                      label={action.label}
                      type="button"
                      color={action.color || 'jade'}
                      onClick={() => action.handler && action.handler()}
                      disabled={
                        action.disabled ||
                        isSaving ||
                        canDismissDialog === false
                      }
                      loading={action.loading || isSaving}
                      theme={props.theme}
                    ></Button>
                  ))}

                  {props.options?.secondaryAction && (
                    <Button
                      label={props.options.secondaryAction.label}
                      type="button"
                      color="plain"
                      onClick={() => {
                        props.options?.secondaryAction?.handler &&
                          props.options?.secondaryAction?.handler()
                      }}
                      loading={
                        props.options?.secondaryAction.loading || isSaving
                      }
                      disabled={
                        props.options?.secondaryAction.disabled ||
                        isSaving ||
                        canDismissDialog === false
                      }
                      style={{
                        position: 'absolute',
                        left: '10px',
                      }}
                    ></Button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
