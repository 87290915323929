import // CalendarIcon,
// ChartPieIcon,
// DocumentDuplicateIcon,
// FolderIcon,
'@heroicons/react/24/outline'
import {
  adminBiomarkersUpdateUrl,
  adminRawSaleIssuesUrl,
  adminResultsCreateUrl,
  collectionCentreUrl,
  // dashboardUrl,
  // resultUrl,
  resultsUrl,
  routes,
  testsUrl,
} from '../../lib/routes'
import { User } from '../../lib/validators'
import {
  BoltIcon,
  // ChartPieIcon,
  InboxArrowDownIcon,
  PlusCircleIcon,
  ClipboardDocumentIcon,
  BuildingStorefrontIcon,
  InformationCircleIcon,
  ChatBubbleBottomCenterIcon,
} from '@heroicons/react/24/solid'
import { ReactNode } from 'react'

export interface MenuItem {
  name: string | ReactNode
  href?: string
  target?: string
  icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
      title?: string | undefined
      titleId?: string | undefined
    } & React.RefAttributes<SVGSVGElement>
  >
  current: boolean
  handler?: () => void
}

export interface MenuItemGroup {
  header: string | ReactNode
  items: MenuItem[]
}

export function menuItems(currentPage: string): MenuItem[] {
  return [
    // {
    //   name: "Dashboard",
    //   href: dashboardUrl,
    //   icon: ChartPieIcon,
    //   current: currentPage === routes[dashboardUrl].name,
    // },
    {
      name: 'Results',
      href: resultsUrl,
      icon: ClipboardDocumentIcon,
      current:
        [routes[resultsUrl].name, routes[resultsUrl].name].indexOf(
          currentPage
        ) !== -1, // TODO THis is a hack, we should fix this.
      // currentPage === (routes[testsUrl].name || routes[resultUrl].name),
    },
    {
      name: 'Referrals',
      href: testsUrl,
      icon: InboxArrowDownIcon,
      current: [routes[testsUrl].name].indexOf(currentPage) !== -1, // TODO THis is a hack, we should fix this.
      // currentPage === (routes[testsUrl].name || routes[resultUrl].name),
    },
  ]
}

export function adminMenuItems(
  user: User | null,
  currentPage: string
): MenuItem[] {
  if (!user) {
    return []
  }
  if (!user?.isAdministrator) return []
  return [
    {
      name: 'Add Results',
      href: adminResultsCreateUrl,
      icon: PlusCircleIcon,
      current: currentPage === routes[adminResultsCreateUrl].name,
    },
    {
      name: 'Update Biomarkers',
      href: adminBiomarkersUpdateUrl,
      icon: BoltIcon,
      current: currentPage === routes[adminBiomarkersUpdateUrl].name,
    },
    {
      name: 'Webhook Sale Issues',
      href: adminRawSaleIssuesUrl,
      icon: InformationCircleIcon,
      current: currentPage === routes[adminRawSaleIssuesUrl].name,
    },
  ]
}

export function practitionerMenuItems(
  user: User | null,
  currentPage: string
): MenuItem[] {
  if (!user) {
    console.log(currentPage)
    return []
  }
  return [
    // {
    //   name: "Dashboard",
    //   href: adminResultsCreateUrl,
    //   icon: PlusCircleIcon,
    //   current: currentPage === routes[adminResultsCreateUrl].name,
    // },
    // {
    //   name: "Clients",
    //   href: adminResultsCreateUrl,
    //   icon: PlusCircleIcon,
    //   current: currentPage === routes[adminResultsCreateUrl].name,
    // },
  ]
}

export function externalMenuItems(
  user: User | null,
  currentPage: string
): MenuItem[] {
  if (!user) {
    return []
  }
  return [
    {
      name: 'Collection Centres',
      href: collectionCentreUrl,
      // href: `${import.meta.env.VITE_SHOP_URL}/collection-centre`,
      icon: BuildingStorefrontIcon,
      // target: "bgt-collection",
      current: currentPage === routes[collectionCentreUrl].name,
    },
    {
      name: 'Contact Us',
      icon: ChatBubbleBottomCenterIcon,
      current: false,
      handler: () => {
        window.Intercom('showNewMessage')
      },
    },
  ]
}
