import { Fragment, PropsWithChildren } from 'react'
import { Bars } from 'react-loading-icons'
import { ThemeInterface } from '../../../lib/interfaces'

export const buttonColors = ['', 'jade', 'blood', 'mandarin', 'plain'] as const
export type ButtonColors = (typeof buttonColors)[number]

// export type ButtonColors = "jade" | "blood" | "mandarin" | "plain"
interface ButtonProps extends PropsWithChildren {
  label: string
  disabled?: boolean
  type?: 'button' | 'submit'
  color?: ButtonColors
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  reference?: React.RefObject<HTMLButtonElement>
  classNames?: string
  loading?: boolean
  theme?: ThemeInterface
  style?: React.CSSProperties
}
export const buttonClasses =
  'rounded-md bg-bgt-primary/90 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-bgt-primary focus-visible:none disabled:bg-gray-dark text-xs'

export function Button({
  label,
  type = 'button',
  children,
  disabled = false,
  color = 'blood',
  reference,
  classNames,
  onClick,
  loading = false,
  theme,
  style,
}: ButtonProps) {
  let classes = buttonClasses

  if (classNames) {
    classes = `${classes} ${classNames}`
  }

  // add the extra theme classes here
  let themeStyle: React.CSSProperties = {}
  if (color) {
    if (color === 'jade') {
      classes += ' bg-jade hover:bg-jade/80'
    }
    if (color === 'mandarin') {
      classes += ' bg-mandarin hover:bg-mandarin/80'
    }

    if (color === 'plain') {
      classes +=
        ' cursor-pointer !bg-transparent !text-gray-semi-dark hover:!bg-transparent hover:!text-blood !shadow-none disabled:!bg-transparent disabled:!cursor-default disabled:!text-gray-semi-dark/80'
    }
  }
  if (
    theme &&
    theme.buttonColor &&
    theme.buttonHoverColor &&
    theme.buttonTextColor &&
    !disabled
  ) {
    classes += ` !bg-[--button-color] hover:!bg-[--button-hover-color] !text-[--button-text-color]`
    themeStyle = {
      '--button-color': theme.buttonColor,
      '--button-hover-color': theme.buttonHoverColor,
      '--button-text-color': theme.buttonTextColor,
    } as React.CSSProperties
  }

  if (loading) {
    classes = `${classes} bg-gray-dark`
  }
  if (disabled) {
    classes = `${classes} !disabled:bg-gray-dark`
  }
  if (style) {
    themeStyle = {
      ...style,
      ...themeStyle,
    }
  }

  return (
    <button
      type={type}
      className={classes}
      disabled={disabled ? disabled : false}
      ref={reference}
      onClick={onClick}
      style={themeStyle}
    >
      <Fragment>
        {label}
        {children}
        {loading && <Bars className="inline ml-2" width={14} height={14} />}
      </Fragment>
    </button>
  )
}
