import { Fragment } from 'react'
import { formatMoney } from '../../lib/utils'
import { PageHeading } from '../layout/PageHeading'
import {
  CalculatedPrice,
  TestWithBiomarkersSelected,
} from '../../lib/validators'
import { TrashIcon } from '@heroicons/react/24/outline'
import { SpinningCircles } from 'react-loading-icons'

interface PriceSummaryProps {
  price: CalculatedPrice
  showSummary: boolean
  tests: TestWithBiomarkersSelected[]
  onRemove?: (test: string) => void
}

export default function PriceSummary({
  price,
  showSummary,
  tests,
  onRemove,
}: PriceSummaryProps) {
  // const [totalPrice, setTotalPrice] = useState(price)

  // useEffect(() => {
  //   let total = 0
  //   tests.forEach((test) =>
  //     test.checked && test.price ? (total += test.price) : 0
  //   )
  //   setTotalPrice(total)
  // }, [tests])

  return (
    <Fragment>
      {price.testsTotal > 0 && (
        <div
          className={`side-panel 2xl:z-50 2xl:fixed 2xl:right-0 2xl:top-[4rem] bg-white/60 dark:bg-transparent dark:2xl:bg-dark-gray-light/60  dark:text-white 2xl:w-96 2xl:h-[calc(100dvh-4rem)] 2xl:shadow-lg 2xl:block 2xl:overflow-auto ${price.testsTotal > 0 ? '' : '2xl:hidden'}`}
        >
          <div className="2xl:p-6">
            {showSummary && (
              <Fragment>
                <PageHeading
                  title="Tests included"
                  size="lg"
                  className="!pb-2 mt-2"
                ></PageHeading>
                <div className="grid grid-cols-8">
                  {tests.map((item) => (
                    <Fragment key={`${item.name}-cart-item`}>
                      {item.checked && (
                        <Fragment>
                          <div className="pb-1 text-sm col-span-6">
                            <div className="inline">{item.name}</div>
                          </div>
                          <span className="block text-gray-dark text-sm text-right col-span-2">
                            {item.price && formatMoney(item.price / 100, 'aud')}
                            {onRemove && (
                              <TrashIcon
                                height={16}
                                className="inline text-blood font-bold cursor-pointer transition-all hover:scale-125"
                                onClick={() => onRemove(item.id)}
                              ></TrashIcon>
                            )}
                          </span>
                        </Fragment>
                      )}
                    </Fragment>
                  ))}
                  <div className=" font-bold text-md border-t border-gray-dark col-span-6">
                    Total test price
                  </div>
                  <div className="font-bold text-md  border-t border-gray-dark text-right col-span-2">
                    {formatMoney(price.testsTotal / 100, 'aud')}
                  </div>
                  {price.calculating && (
                    <Fragment key={Math.random()}>
                      <div className="italic text-sm border-t border-gray-dark col-span-6">
                        Calculating fees
                      </div>
                      <div className="text-md  border-t border-gray-dark text-gray-dark text-sm text-right col-span-2">
                        <SpinningCircles
                          width={16}
                          height={16}
                          stroke={`#FF3A5F`}
                          fill={`#FF3A5F`}
                          className="inline"
                        ></SpinningCircles>
                      </div>
                    </Fragment>
                  )}
                  {!price.calculating &&
                    price.fees.map((fee) => (
                      <Fragment key={Math.random()}>
                        <div className="italic text-md  col-span-6 text-sm">
                          {fee.reason}
                        </div>
                        <div className="pb-2  text-md  text-gray-dark text-sm text-right col-span-2">
                          {formatMoney(fee.amount / 100, 'aud')}
                        </div>
                      </Fragment>
                    ))}
                  <div className="pb-2 font-bold text-md border-t border-gray-dark col-span-6">
                    Subtotal
                  </div>
                  <div className="pb-2 font-bold text-md  border-t border-gray-dark text-right col-span-2">
                    {formatMoney(price.subTotal / 100, 'aud')}
                  </div>
                  {price.calculating && (
                    <Fragment key={Math.random()}>
                      <div className="italic text-sm border-t border-gray-dark col-span-6">
                        Calculating final price
                      </div>
                      <div className="text-md  border-t border-gray-dark text-gray-dark text-sm text-right col-span-2">
                        <SpinningCircles
                          width={16}
                          height={16}
                          stroke={`#FF3A5F`}
                          fill={`#FF3A5F`}
                          className="inline"
                        ></SpinningCircles>
                      </div>
                    </Fragment>
                  )}
                  {!price.calculating &&
                    price.discounts.map((discount) => (
                      <Fragment key={Math.random()}>
                        <div className="italic text-sm  col-span-6">
                          {discount.reason}
                        </div>
                        <div className="pb-2  text-md   text-gray-dark text-sm text-right col-span-2">
                          {formatMoney(-discount.amount / 100, 'aud')}
                        </div>
                      </Fragment>
                    ))}
                </div>
                {!price.calculating && (
                  <div className="grid grid-cols-8">
                    <div className="pb-2 font-bold text-md border-t border-gray-dark col-span-4">
                      Final Price
                    </div>
                    <div className="pb-2 font-bold text-md  border-t border-gray-dark text-right col-span-4">
                      {price.subTotal > price.grandTotal && (
                        <span className="line-through text-gray-dark">
                          {formatMoney(price.subTotal / 100, 'aud')}
                        </span>
                      )}{' '}
                      {formatMoney(price.grandTotal / 100, 'aud')}
                    </div>
                    <div className="pb-2 font-bold col-span-8 text-sm text-gray-dark">
                      Prices include GST
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
      )}
    </Fragment>
  )
}
