import { useEffect, useRef, useState } from 'react'
import '../../../App.css'
import { useUser } from '../../../lib/store'
import TextInputField, { onChangeInterface } from '../../form/TextInputField'
import { authFetch } from '../../../providers/AuthProvider'
import {
  BiomarkerItem,
  BiomarkerListSchema,
  PathologyProvider,
  PathologyProviderListSchema,
  ReferralStatusType,
  ReferralStatusSchemaList,
  // ReferralResult,
  Biomarker,
} from '../../../lib/validators'
import SimpleSelectField, {
  SimpleSelectItemProps,
} from '../../form/SimpleSelectField'
import toast from 'react-hot-toast'
import { PageHeading } from '../../layout/PageHeading'
import {
  AcceptedFileTypes,
  FileDrop,
  FileUploadElement,
  UploadDict,
  UploadStatus,
} from '../../form/FileDrop'
import { CheckCircleIcon, DocumentTextIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import PageSection from '../../layout/PageSection'
import {
  getPrettyDate,
  getPrettyDateAndTime,
  setDocumentTitle,
  simpleMdTransform,
} from '../../../lib/utils'

import { Textarea } from '../../form/Textarea'
import AdminBiomarkerElement from '../../admin/AdminBiomarkerElement'
import Checkbox from '../../form/Checkbox'
import { getResultsDataForProfileAndUuid } from '../../../lib/results'
import AdminPage from './AdminPage'

interface ReferralSelectItemProps extends SimpleSelectItemProps {
  profileUuid?: string
  organisationUuid?: string
  testName: string
  uuid: string
  resultUuid?: string
}

interface UploadedFile {
  filename?: string
  filesize?: number
}

type ButtonState = 'Complete and Send to Customer' | 'Mark for review'

export default function AdminCreateResultsPage() {
  const currentTime = getPrettyDateAndTime(new Date())

  const user = useUser()
  const navigate = useNavigate()
  const formRef = useRef<HTMLFormElement>(null)
  const [issuedReferrals, setIssuedReferrals] = useState<
    ReferralSelectItemProps[]
  >([])
  const [selectedReferral, setSelectedReferral] =
    useState<ReferralSelectItemProps | null>(null)
  const [profileForSelectedReferral, setProfileForSelectedReferral] = useState<
    string | null
  >(null)
  const [organisationForSelectedReferral, setOrganisationForSelectedReferral] =
    useState<string | null>(null)
  const [selectedPathologyProvider, setSelectedPathologyProvider] = useState<
    string | null
  >(null)
  const [biomarkers, setBiomarkers] = useState<BiomarkerItem[] | null>(null)
  const [biomarkerResults, setBiomarkerResults] = useState<
    Record<string, Biomarker>
  >({})
  const [pathologyProviders, setPathologyProviders] = useState<
    SimpleSelectItemProps[]
  >([])
  const [resultName, setResultName] = useState<string>('')
  const [bgSummary, setBgSummary] = useState<string>('')
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [initComplete, setInitComplete] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [uploads, setUploads] = useState<UploadDict>({})
  const [existingUploads, setExistingUploads] = useState<UploadedFile[]>([])
  const [preview, setPreview] = useState<string>('')
  const [saveButtonLabel, setSaveButtonLabel] =
    useState<ButtonState>('Mark for review')
  const [resultDate, setResultDate] = useState<string>(currentTime)
  const [inReview, setInReview] = useState(false)

  setDocumentTitle('Admin: Add Results')

  // first we need to get the current tests without results.

  const getIssuedReferrals = async (): Promise<ReferralStatusType[]> => {
    // setTimeout(async () => {
    const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        query: `{
          referralsByStatus(status:"ISSUED") {
            uuid
            status
            reference
            profileUuid
            organisationUuid
            testName
            customerName
            createdAt
            resultUuid
          }
        }`,
      }),
    })
    const data = result.data
    console.log('the data is', data.data)
    const parsedData = ReferralStatusSchemaList.parse(
      data.data.referralsByStatus
    )
    console.log(parsedData)
    return parsedData
  }

  const getPathologyProviders = async (): Promise<PathologyProvider[]> => {
    // setTimeout(async () => {
    const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        query: `{
          pathologyProviders {
            id
            name
          }
        }`,
      }),
    })
    const data = result.data
    const parsedData = PathologyProviderListSchema.parse(
      data.data.pathologyProviders
    )
    return parsedData
  }

  const getBiomarkersForReferralUuid = async (
    referralUuid: string
  ): Promise<BiomarkerItem[]> => {
    // setTimeout(async () => {
    const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        query: `{
          biomarkersForReferral(referralUuid:"${referralUuid}") {
            id
            name
            commonName
            suffix
            prefix
            unit
            refIntervalHigh
            refIntervalLow
            description
            refIntervalHighNotes
            refIntervalLowNotes
            refIntervalMidNotes
          }
        }`,
      }),
    })
    const data = result.data
    const parsedData = BiomarkerListSchema.parse(
      data.data.biomarkersForReferral
    )
    return parsedData
  }

  useEffect(() => {
    // console.log("the admin user", user?.isAdministrator);

    if (!initComplete) {
      getIssuedReferrals().then((data) => {
        setIssuedReferrals(
          data.map((item) => ({
            value: item.uuid,
            label: `${item.resultUuid ? '⚠️ ' : ''}${item.customerName} - ${
              item.testName
            } - ${getPrettyDate(item.createdAt)} - ${item.reference} - ${
              item.status
            }${item.resultUuid ? ' - REQUIRES REVIEW ⚠️' : ''}`,
            profileUuid: item.profileUuid || undefined,
            organisationUuid: item.organisationUuid || undefined,
            testName: item.testName,
            uuid: item.uuid,
            resultUuid: item.resultUuid || undefined,
          }))
        )
      })
      getPathologyProviders().then((data) => {
        console.log('final data is', data)
        setPathologyProviders(
          data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        )
      })
      setInitComplete(true)
    }
  }, [initComplete, user, navigate])

  useEffect(() => {
    if (selectedReferral !== null) {
      resetForm()
      setInReview(false)

      // we need to go and fetch all the biomarkers related to this particular referral, ie look up the test
      getBiomarkersForReferralUuid(selectedReferral.value.toString()).then(
        (items) => setBiomarkers(items)
      )

      console.log('[][][]', selectedReferral)
      if (selectedReferral.resultUuid) {
        getResultsDataForProfileAndUuid({
          resultUuid: selectedReferral.resultUuid,
          profileUuid: selectedReferral.profileUuid,
          organisationUuid: selectedReferral.organisationUuid,
          include: ['files'],
        }).then((item) => {
          console.log('====', item)
          if (item) {
            if (item.status === 'IN_REVIEW') {
              setInReview(true)
            }
            // map the biomarkers into a dict
            item.biomarkers?.map((item) => {
              setBiomarkerResults((prevState) => ({
                ...prevState,
                [item.id]: item,
              }))
            })
            setSelectedPathologyProvider(item.pathologyProviderId)
            setResultName(item.name)
            setResultDate(getPrettyDateAndTime(item.resultDate))
            if (item.notes) {
              setBgSummary(item.notes)
            }
            if (item.files && item.files.length > 0) {
              setExistingUploads(item.files)
            }
            // setBiomarkerResults(item.result.biomarkers);
          }
        })
      }
    }
  }, [selectedReferral])

  useEffect(() => {
    setPreview(simpleMdTransform(bgSummary))
  }, [bgSummary])

  useEffect(() => {
    setResultName(``)
    if (selectedReferral !== null && selectedPathologyProvider !== null) {
      setResultName(`${selectedReferral.testName}`)
      if (biomarkers !== null && biomarkers.length > 0) {
        setSaveEnabled(true)
      }
    } else {
      setSaveEnabled(false)
    }
  }, [selectedReferral, selectedPathologyProvider, biomarkers])

  const handleReferralSelectChange = (data: onChangeInterface) => {
    // console.log("the thing is", data);
    // const select = e.target as HTMLSelectElement;
    // const value = select.options[select.selectedIndex].value;
    // console.log("the value is", data.value.toString());
    let theSselectedReferral: ReferralSelectItemProps | null = null
    issuedReferrals.forEach((item) => {
      // console.log("item", item);
      if (item.value === data.value.toString()) {
        console.log('we found the referral!', item)
        setProfileForSelectedReferral(item.profileUuid || null)
        setOrganisationForSelectedReferral(item.organisationUuid || null)
        theSselectedReferral = item
        return null
      }
      // return null
    })

    setSelectedReferral(theSselectedReferral)
  }
  const handlePathologySelectChange = (data: onChangeInterface) => {
    console.log('pathology is', data)
    setSelectedPathologyProvider(
      data.value.toString() || data.label || data.name
    )
  }

  interface CreateResultsFormElement extends HTMLFormElement {
    readonly elements: HTMLFormControlsCollection
  }

  interface BiomarkerRequestPayload {
    id: string
    value: number
    refIntervalHigh?: number | null
    refIntervalLow?: number | null
    refIntervalHighNotes?: string | null
    refIntervalLowNotes?: string | null
    refIntervalMidNotes?: string | null
    description?: string | null
  }

  type BiomakerMap = Record<string, BiomarkerRequestPayload>

  const handleSubmit = async (
    event: React.FormEvent<CreateResultsFormElement>
  ) => {
    event.preventDefault()
    // event.currentTarget.elements.biomarker;
    const formData: Record<string, unknown> = {
      biomarkers: [],
    }
    const biomakerMap: BiomakerMap = {}
    ;[...event.currentTarget.elements].forEach((item) => {
      switch (item.nodeName.toLowerCase()) {
        case 'select': {
          const selectItem = item as HTMLSelectElement
          formData[selectItem.getAttribute('name')!] = selectItem.value
          break
        }
        case 'textarea': {
          const selectItem = item as HTMLTextAreaElement
          const elementName = selectItem.getAttribute('name')!

          if (elementName.indexOf('biomarker_') === 0) {
            const parts = elementName.split('_')
            const biomarkerUuid = parts[1]
            const biomarkerValue = selectItem.value
            // console.log("biomarkeruuid", biomarkerUuid, biomarkerValue);
            if (parts[parts.length - 1] === 'description') {
              biomakerMap[biomarkerUuid] = {
                ...biomakerMap[biomarkerUuid],
                description: biomarkerValue,
              }
            } else if (parts[parts.length - 1] === 'high-ref-notes') {
              biomakerMap[biomarkerUuid] = {
                ...biomakerMap[biomarkerUuid],
                refIntervalHighNotes: biomarkerValue,
              }
            } else if (parts[parts.length - 1] === 'low-ref-notes') {
              biomakerMap[biomarkerUuid] = {
                ...biomakerMap[biomarkerUuid],
                refIntervalLowNotes: biomarkerValue,
              }
            } else if (parts[parts.length - 1] === 'mid-ref-notes') {
              biomakerMap[biomarkerUuid] = {
                ...biomakerMap[biomarkerUuid],
                refIntervalMidNotes: biomarkerValue,
              }
            }
          } else {
            formData[elementName!] = selectItem.value
          }

          break
        }
        case 'input': {
          const textItem = item as HTMLInputElement
          // make sure we are a text element
          if (
            textItem.type === 'text' ||
            textItem.type === 'number' ||
            textItem.type === 'datetime-local'
          ) {
            const elementName = textItem.getAttribute('name')!
            // handle if its a biomarker
            if (elementName.indexOf('biomarker_') === 0) {
              const parts = elementName.split('_')
              console.log('parts', parts)
              const biomarkerUuid = parts[1]
              const biomarkerValue = parseFloat(textItem.value)
              if (parts[parts.length - 1] === 'ref-high') {
                biomakerMap[biomarkerUuid] = {
                  ...biomakerMap[biomarkerUuid],
                  refIntervalHigh: isNaN(biomarkerValue)
                    ? null
                    : biomarkerValue,
                }
              } else if (parts[parts.length - 1] === 'ref-low') {
                biomakerMap[biomarkerUuid] = {
                  ...biomakerMap[biomarkerUuid],
                  refIntervalLow: isNaN(biomarkerValue) ? null : biomarkerValue,
                }
              } else {
                biomakerMap[biomarkerUuid] = {
                  id: biomarkerUuid,
                  value: biomarkerValue,
                }
              }
              // eslint-disable-next-line no-extra-semi
              ;(formData['biomarkers'] as BiomarkerRequestPayload[]).push({
                id: biomarkerUuid,
                value: biomarkerValue,
              })
            } else {
              formData[textItem.getAttribute('name')!] = textItem.value
            }
          } else if (textItem.type === 'checkbox') {
            // handle the checkbox differently
            const checkboxItem = item as HTMLInputElement
            formData[checkboxItem.getAttribute('name')!] = textItem.checked
          } else {
            console.log('we need to handle this file input?')
          }
          break
        }
        default: {
          return
        }
      }
    })
    formData['biomarkers'] = Object.values(biomakerMap)
    // console.log(formData);
    // console.log(Object.values(biomakerMap));
    // return;

    setProcessing(true)
    // save the data
    const toastId = toast.loading('Uploading...', {
      duration: 3000,
      icon: '⤴️',
    })

    let fileUuids: string[] = []
    if (Object.keys(uploads).length > 0) {
      fileUuids = await uploadFiles()
      formData['fileUuids'] = fileUuids
    }

    toast.loading('Saving...', {
      duration: 3000,
      icon: '💾',
      id: toastId,
    })

    const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        query: `
          mutation name($data:CreateResultInput!) {
            createResult(data: $data) {
              uuid
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          data: formData,
        },
      }),
    })

    const response = result.data
    if (response.errors) {
      toast.error(response.errors[0].message, {
        duration: 5000,
        id: toastId,
        icon: '❌',
      })
      setProcessing(false)
    } else {
      toast.success('Results saved.', {
        duration: 3000,
        id: toastId,
        icon: '✅',
      })
      // reset the fields
      resetForm()
    }
  }

  // for results extraction
  const uploadResultsAndExtractData = async (
    items: UploadDict
  ): Promise<string[]> => {
    const promises: Promise<string>[] = []
    if (!selectedReferral) {
      toast.error('Please select a referral first', {
        duration: 3000,
      })
      throw new Error('Missing referral')
    }
    Object.values(items).forEach(async (item) => {
      promises.push(uploadResultAndExtractData(item))
    })
    return await Promise.all(promises)
  }
  const uploadResultAndExtractData = async (
    item: FileUploadElement
  ): Promise<string> => {
    const payload = new FormData()
    payload.append('file', item.file)
    if (biomarkers) {
      payload.append(
        'expectedBiomarkers',
        biomarkers?.map((item) => item.id).join(',')
      )
    }

    const result = await authFetch(
      `${import.meta.env.VITE_API_URL}/pdf-extract`,
      {
        method: 'POST',
        headers: {
          'content-type': 'multipart/form-data',
        },
        data: payload,
      }
    )
    console.log('result is', result.data)
    if (result && result.data && result.data.biomarkers) {
      result.data.biomarkers.map((item: Biomarker) => {
        console.log('lets update teh biomarker results', item)
        setBiomarkerResults((prevState) => ({
          ...prevState,
          [item.id]: item,
        }))
      })
    }
    return ''
  }

  const uploadFiles = async (): Promise<string[]> => {
    const promises: Promise<string>[] = []
    Object.values(uploads).forEach(async (item) => {
      promises.push(uploadFile(item))
    })
    return await Promise.all(promises)
  }
  const uploadFile = async (item: FileUploadElement): Promise<string> => {
    const payload = new FormData()
    payload.append('file', item.file)
    if (profileForSelectedReferral) {
      payload.append('profileUuid', profileForSelectedReferral)
    }
    if (organisationForSelectedReferral) {
      payload.append('organisationUuid', organisationForSelectedReferral)
    }
    // update its status
    item.status = UploadStatus.Uploading
    setUploads((prev) => ({
      ...prev,
      [item.file.name]: item,
    }))
    const result = await authFetch(`${import.meta.env.VITE_API_URL}/upload`, {
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: payload,
      onUploadProgress: (progressEvent) => {
        console.log('got some upload progress!')
        if (progressEvent && progressEvent.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          console.log('progress is', item, percentCompleted)
          item.uploadProgress = 100 - percentCompleted
          setUploads((prev) => ({
            ...prev,
            [item.file.name]: item,
          }))
        }
      },
    })
    if (result && result.data && result.data.fileUuid) {
      console.log('we got a file uuid', result.data)
      item.status = UploadStatus.Complete
      item.uuid = result.data.fileUuid
      setUploads((prev) => ({
        ...prev,
        [item.file.name]: item,
      }))
      return result.data.fileUuid
    }
    return ''
  }

  const resetForm = () => {
    formRef.current?.reset()
    setResultName('')
    setBiomarkers(null)
    setInitComplete(false)
    setProcessing(false)
    setUploads({})
    setSaveEnabled(false)
    setBgSummary('')
    setBiomarkerResults({})
    setResultDate('')
    setSelectedPathologyProvider('')
    setExistingUploads([])
    setSaveButtonLabel('Mark for review')
    // setPreview("");
  }

  const acceptedFiles: AcceptedFileTypes = {
    'application/pdf': ['.pdf'],
  }

  const handleFileDrop = (files: File[]): void => {
    // setUploads(files);
    const items: UploadDict = {}
    files.forEach((item) => {
      items[item.name] = {
        file: item,
        status: UploadStatus.Ready,
        uploadProgress: 0,
      }
    })
    console.log(items)
    setUploads(items)
  }

  const handleDropResultsFiles = (files: File[]): void => {
    const items: UploadDict = {}
    files.forEach((item) => {
      items[item.name] = {
        file: item,
        status: UploadStatus.Ready,
        uploadProgress: 0,
      }
    })
    if (Object.keys(items).length > 0) {
      uploadResultsAndExtractData(items)
    }
  }

  const confirmSendToUser = (e: onChangeInterface) => {
    if (e.value === true) {
      setSaveButtonLabel('Complete and Send to Customer')
    } else {
      setSaveButtonLabel('Mark for review')
    }
  }

  return (
    <AdminPage>
      <PageSection>
        <form className="max-w-5xl" onSubmit={handleSubmit} ref={formRef}>
          <PageHeading
            title="Create Results"
            description="Given a test reference number, please complete the form below to provide results to a test"
          ></PageHeading>

          <div className="w-full pt-10">
            <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
              <SimpleSelectField
                label="Referral Reference Code"
                name="referralUuid"
                firstItemText="Select reference code"
                options={issuedReferrals}
                handleChange={handleReferralSelectChange}
                value={selectedReferral ? selectedReferral.uuid : ''}
              />
            </div>
            <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
              <SimpleSelectField
                label="Pathology Lab"
                name="pathologyProviderId"
                firstItemText="Select pathology lab"
                value={selectedPathologyProvider || ''}
                options={pathologyProviders}
                required
                handleChange={handlePathologySelectChange}
              />
            </div>
            <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
              <TextInputField
                id={resultName}
                label="Results name (displayed to the user in the Results list page)"
                value={resultName}
                name="name"
                type="text"
                required={true}
                handleChange={(data: onChangeInterface) =>
                  setResultName(data.value.toString())
                }
              />
            </div>
            <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
              <TextInputField
                id="resultDate"
                label="Results Date"
                name="resultDate"
                type="datetime-local"
                max={currentTime}
                required={true}
                value={resultDate}
                handleChange={(data: onChangeInterface) =>
                  setResultDate(data.value.toString())
                }
              />
            </div>
            <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
              <FileDrop
                acceptedFileTypes={acceptedFiles}
                multiple={true}
                onDropCallback={handleDropResultsFiles}
                label="Drop results here to extract results."
              />
            </div>
            {biomarkers !== null && biomarkers.length > 0 && (
              <h3 className="text-base font-semibold leading-7 text-gray-900  dark:text-white">
                Biomarkers for referral
              </h3>
            )}
            {biomarkers !== null &&
              biomarkers.length > 0 &&
              biomarkers.map((item, k) => (
                <div
                  key={`biomarker-${k}`}
                  className="mt-1 text-sm leading-6 text-gray-600 pt-3 pb-5 border-b border-b-gray-dark/80"
                >
                  <AdminBiomarkerElement
                    showDescriptionFields={inReview}
                    biomarker={item}
                    value={
                      (biomarkerResults[item.id] &&
                        biomarkerResults[item.id].value.toString()) ||
                      ''
                    }
                    lowRef={
                      (biomarkerResults[item.id] &&
                        biomarkerResults[item.id].refIntervalLow?.toString()) ||
                      ''
                    }
                    highRef={
                      (biomarkerResults[item.id] &&
                        biomarkerResults[
                          item.id
                        ].refIntervalHigh?.toString()) ||
                      ''
                    }
                    lowRefNotes={
                      (biomarkerResults[item.id] &&
                        biomarkerResults[
                          item.id
                        ].refIntervalLowNotes?.toString()) ||
                      ''
                    }
                    midRefNotes={
                      (biomarkerResults[item.id] &&
                        biomarkerResults[
                          item.id
                        ].refIntervalMidNotes?.toString()) ||
                      ''
                    }
                    highRefNotes={
                      (biomarkerResults[item.id] &&
                        biomarkerResults[
                          item.id
                        ].refIntervalHighNotes?.toString()) ||
                      ''
                    }
                    description={
                      (biomarkerResults[item.id] &&
                        biomarkerResults[item.id].description?.toString()) ||
                      ''
                    }
                    handleChange={(data: onChangeInterface) =>
                      console.log('change', data)
                    }
                  ></AdminBiomarkerElement>
                </div>
              ))}
            {biomarkers !== null && biomarkers.length === 0 && (
              <div>
                No biomakers found for the set of tests associated with this
                referral
              </div>
            )}
            <div className="mt-8 text-sm leading-6 text-gray-600 pb-5">
              <Textarea
                id="comment"
                name="comment"
                label="Bloody Good Summary"
                handleChange={(e) => setBgSummary(e.value.toString())}
                value={bgSummary}
              ></Textarea>
            </div>
            <div className="preview rounded-md bg-gray-light dark:bg-dark-gray-light dark:text-white p-2 mb-4">
              <label className="block text-sm font-medium leading-6 text-gray-dark mb-2">
                Summary preview
              </label>
              <div dangerouslySetInnerHTML={{ __html: preview }}></div>
            </div>
            <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
              <FileDrop
                acceptedFileTypes={acceptedFiles}
                multiple={true}
                onDropCallback={handleFileDrop}
              />
              {Object.keys(uploads).length > 0 && (
                <div className="mt-4">
                  {Object.values(uploads).map((file, idx) => (
                    <FileDropFile
                      key={`file-${idx}`}
                      filesize={file.file.size}
                      filename={file.file.name}
                      // file={file.file}
                      uploadProgress={file.uploadProgress}
                      status={file.status}
                    ></FileDropFile>
                  ))}
                </div>
              )}
              {Object.keys(existingUploads).length > 0 && (
                <div className="mt-4">
                  {Object.values(existingUploads).map((file, idx) => (
                    <FileDropFile
                      key={`file-${idx}`}
                      filesize={file.filesize}
                      filename={file.filename}
                      // file={file.file}
                      uploadProgress={100}
                      status={UploadStatus.Complete}
                    ></FileDropFile>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
            <Checkbox
              id="complete"
              name="complete"
              type="checkbox"
              disabled={!saveEnabled || processing}
              onChange={confirmSendToUser}
              checkboxLabel={
                <b>
                  ⚠️ Mark referral as complete and send results to customer ⚠️
                </b>
              }
            ></Checkbox>
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={resetForm}
              className="text-sm font-semibold leading-6 text-gray-900  dark:text-white"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                saveButtonLabel !== 'Mark for review'
                  ? 'bg-bgt-primary/90 hover:bg-bgt-primary'
                  : 'bg-jade/90 hover:bg-jade'
              } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-dark`}
              disabled={!saveEnabled || processing}
            >
              {saveButtonLabel}
            </button>
          </div>
        </form>
      </PageSection>
    </AdminPage>
  )
}

interface FileDropFileProps {
  // file: File;
  filename?: string
  filesize?: number
  uploadProgress: number
  status: UploadStatus
}

export function FileDropFile({
  filename,
  filesize,
  uploadProgress,
  status,
}: FileDropFileProps) {
  const getSize = (): string => {
    if (filesize === undefined) {
      return 'unknown size'
    }
    return `${Math.round(filesize / 1000)}kb`
  }
  return (
    <div
      className={`p-4 border-solid border-2 border-gray-dark/20 rounded-lg mb-2 flex relative`}
    >
      <div className="size-10 mr-2">
        {status === UploadStatus.Ready && (
          <DocumentTextIcon width={40} className=""></DocumentTextIcon>
        )}
        {status === UploadStatus.Complete && (
          <CheckCircleIcon width={40} className=""></CheckCircleIcon>
        )}
        {status === UploadStatus.Uploading && (
          <div className="size-8 relative">
            <svg
              className="size-full"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="18"
                cy="18"
                r="16"
                fill="none"
                className="stroke-current text-gray-200 dark:text-gray-700"
                strokeWidth="4"
              ></circle>
              <g className="origin-center -rotate-90 transform">
                <circle
                  cx="18"
                  cy="18"
                  r="16"
                  fill="none"
                  className="stroke-current text-bgt-primary dark:text-bgt-primary"
                  strokeWidth="4"
                  strokeDasharray="100"
                  strokeDashoffset={uploadProgress}
                ></circle>
              </g>
            </svg>
          </div>
        )}
      </div>
      <div className="max-w-72">
        <p className="truncate leading-4 font-bold p-0">{filename}</p>
        <small>({getSize()})</small>
      </div>
    </div>
  )
}
