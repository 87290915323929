import { Fragment, useEffect, useState } from 'react'
import { ResultReferral } from '../../../lib/validators'

import { setDocumentTitle, simpleMdTransform } from '../../../lib/utils'
import {
  defaultConclusion,
  defaultDisclaimer,
  defaultThankYou,
} from '../../../lib/results'
import { useUser } from '../../../lib/store'
import { Link } from 'react-router-dom'
import { ResultsTabProps } from '../../../lib/interfaces'

export const ResultTabOverview: React.FC<ResultsTabProps> = ({
  resultReferral,
}) => {
  setDocumentTitle(`${resultReferral?.referral?.testName} Result`)

  // export function ResultTabOverview({ referralResult }: ResultsTabProps) {
  const [result, setResult] = useState<ResultReferral>()
  const user = useUser()

  useEffect(() => {
    setResult(resultReferral)
  }, [])

  return (
    <Fragment>
      {!result && <div>Loading...</div>}
      {result && (
        <div className="text-gray-semi-dark dark:text-white text-sm select-none">
          <p className="mt-6">Hi {user?.firstName},</p>
          <p className="mt-6">{defaultThankYou}</p>
          <p className="mt-4">
            If you'd like to skip straight to your biomarkers,{' '}
            <Link to="biomarkers" className="text-blood hover:underline">
              click here
            </Link>
          </p>
          <p className="mt-4">
            You can also download your pathology reports on the{' '}
            <Link to="attachments" className="text-blood hover:underline">
              attachments tab
            </Link>
          </p>

          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: simpleMdTransform(result.notes) || '',
            }}
          ></div>
          <br />
          <div
            className="select-none"
            dangerouslySetInnerHTML={{
              __html: simpleMdTransform('## Conclusion') || '',
            }}
          ></div>
          <p
            className=" text-sm select-none"
            dangerouslySetInnerHTML={{
              __html: defaultConclusion || '',
            }}
          ></p>
          <hr className="mt-6 mb-6 dark:border-dark-gray-light" />
          <div
            className="select-none"
            dangerouslySetInnerHTML={{
              __html: simpleMdTransform('## Disclaimer') || '',
            }}
          ></div>
          <p
            className=" dark:text-gray-dark text-sm select-none"
            dangerouslySetInnerHTML={{
              __html: defaultDisclaimer || '',
            }}
          ></p>
        </div>
      )}
    </Fragment>
  )
}
