export enum ReferralStatus {
  PendingVerification = "PENDING_VERIFICATION",
  Created = "CREATED",
  Issued = "ISSUED",
  Complete = "COMPLETE",
  InsufficientData = "INSUFFICIENT_DATA",
  Refunded = "REFUNDED",
  TestFailed = "FAILED_TEST",
}

export enum GenderTypes {
  Male = "male",
  Female = "female",
}
