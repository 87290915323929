import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import { OrganisationWithBranding } from '../components/context/OrganisationContext'
import { authFetch } from '../providers/AuthProvider'
import {
  AddCardResponse,
  AddCardResponseSchema,
  BillingCurrent,
  BillingCurrentSchema,
  BillingCurrentSubscription,
  BillingCurrentSubscriptionSchema,
  BillingInvoiceList,
  BillingInvoiceListSchema,
  CreateStripeCustomer,
  CreateStripeCustomerSchema,
  CreateStripeSubscription,
  CreateStripeSubscriptionSchema,
  StripeAddress,
  UpdateCardRequestResponse,
  UpdateCardRequestResponseSchema,
} from './validators'

interface CreateStripeCustomerProps {
  address?: StripeAddress
  name?: string
  phone?: string
  organisationUuid: string
  organisationName: string
  abn?: string
  entityName?: string
}

export interface CreateStripeSubscriptionProps {
  organisationUuid: string
  productId: string
  customerId?: string
}

export const createStripeCustomer = async (
  props: CreateStripeCustomerProps
): Promise<CreateStripeCustomer> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($data:CreateStripeCustomerInput!) {
            createStripeCustomer(data: $data) {
              id
              name
              phone
              address {
                line1
                line2
                postalCode
                city
                state
                country
              }
            }
          }
        `,
      variables: {
        data: {
          address: props.address,
          name: props.name,
          phone: props.phone,
          organisationUuid: props.organisationUuid,
          organisationName: props.organisationName,
          entityName: props.entityName,
          abn: props.abn,
        } as CreateStripeCustomerProps,
      },
    }),
  })
  console.log('the data is', result.data.data)
  const customer = CreateStripeCustomerSchema.parse(
    result.data.data.createStripeCustomer
  )
  return customer
}

export const createStripeSubscription = async (
  props: CreateStripeSubscriptionProps
): Promise<CreateStripeSubscription> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($data:CreateStripeSubscriptionInput!) {
            createStripeSubscription(data: $data) {
              clientSecret
              type
              productId
              customerId
              subscriptionId
            }
          }
        `,
      variables: {
        data: {
          organisationUuid: props.organisationUuid,
          productId: props.productId,
          customerId: props.customerId,
        },
      },
    }),
  })
  console.log('the data is', result.data.data)
  const billing = CreateStripeSubscriptionSchema.parse(
    result.data.data.createStripeSubscription
  )
  return billing
}

export const getCurrentSubscription = async (
  organisation: OrganisationWithBranding
): Promise<BillingCurrentSubscription | null> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          getCurrentSubscription(organisationUuid: "${organisation?.uuid}") {
            id
            currency
            currentPeriodStart
            currentPeriodEnd
            trialStart
            trialEnd
            planActive
            price
            interval
            status
            cancelAtEndPeriod
            cancelledAt
            internalPlanId
            internalPlanName
            payment {
              type
              brand
              expMonth
              expYear
              last4
              name
              email
              phone
              address {
                line1
                line2
                city
                state
                postalCode
                country
              }
            }
          }}`,
    }),
  })
  const data = result.data
  if (data.errors) {
    console.error(data.errors)
    // throw new Error("Api returned errors");
  }
  if (data.data.getCurrentSubscription === null) {
    return null
  }
  const parsedData = BillingCurrentSubscriptionSchema.parse(
    data.data.getCurrentSubscription
  )
  // const resultFiles = FileListSchema.parse(data.data.filesForResult);
  return parsedData
}

const currentBillingFields = `
{
            uuid
            subscription {
              id
              currency
              currentPeriodStart
              currentPeriodEnd
              trialStart
              trialEnd
              planActive
              price
              interval
              status
              cancelAtEndPeriod
              cancelledAt
              internalPlanId
              internalPlanName
              payment {
                type
                brand
                expMonth
                expYear
                last4
                name
                email
                phone
                address {
                  line1
                  line2
                  city
                  state
                  postalCode
                  country
                }
              }
            }
            stripeProductId
            currentPlanId
            hasTrialed
            customer {
              id
              name
              phone
              email
              address {
                line1
                line2
                city
                state
                postalCode
                country
              }
            }
            defaultPaymentMethod {
              id
              type
              brand
              expMonth
              expYear
              last4
              name
              email
              phone
              address {
                line1
                line2
                city
                state
                postalCode
                country
              }
            }
            secondaryPaymentMethod {
              id
              type
              brand
              expMonth
              expYear
              last4
              name
              email
              phone
              address {
                line1
                line2
                city
                state
                postalCode
                country
              }
            }
          }
`

export const getCurrentBilling = async (
  organisation: OrganisationWithBranding
): Promise<BillingCurrent | null> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          getCurrentBilling(organisationUuid: "${organisation?.uuid}")
          ${currentBillingFields}
        }`,
    }),
  })
  const data = result.data
  if (data.errors) {
    console.error(data.errors)
    // throw new Error("Api returned errors");
  }
  if (data.data.getCurrentBilling === null) {
    return null
  }
  console.log('we got the data', data.data)
  const parsedData = BillingCurrentSchema.parse(data.data.getCurrentBilling)
  console.log('the parsed data is', parsedData)
  // const resultFiles = FileListSchema.parse(data.data.filesForResult);
  return parsedData
}

export const cancelCurrentSubscription = async (
  organisation: OrganisationWithBranding,
  shouldCancel: boolean
): Promise<BillingCurrentSubscription> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($organisationUuid: UUID!, $data:CancelSubscriptionInput!) {
            cancelSubscription(organisationUuid: $organisationUuid data: $data) {
              id
              currency
              currentPeriodStart
              currentPeriodEnd
              trialStart
              trialEnd
              planActive
              price
              interval
              status
              cancelAtEndPeriod
              cancelledAt
              internalPlanId
              internalPlanName
              payment {
                type
                brand
                expMonth
                expYear
                last4
                name
                email
                phone
                address {
                  line1
                  line2
                  city
                  state
                  postalCode
                  country
                }
              }
            }
          }`,
      variables: {
        organisationUuid: organisation?.uuid,
        data: {
          shouldCancel: shouldCancel,
          cancelImmediately: false,
        },
      },
    }),
  })
  const data = result.data
  if (data.errors) {
    console.error(data.errors)
  }
  const parsedData = BillingCurrentSubscriptionSchema.parse(
    data.data.cancelSubscription
  )
  return parsedData
}

export const updateCardRequest = async (
  organisation: OrganisationWithBranding
): Promise<UpdateCardRequestResponse> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
            updateCardRequest(organisationUuid: "${organisation.uuid}" ) {
              clientSecret
              type
              productId
              customerId
              subscriptionId
            }
          }`,
    }),
  })
  const data = result.data
  if (data.errors) {
    console.error(data.errors)
  }
  console.log('we got some data?', data)
  const parsedData = UpdateCardRequestResponseSchema.parse(
    data.data.updateCardRequest
  )
  return parsedData
}

export const addCardRequest = async (
  organisation: OrganisationWithBranding
): Promise<AddCardResponse> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
            addCardRequest(organisationUuid: "${organisation.uuid}" ) {
              clientSecret
              type
              customerId
            }
          }`,
    }),
  })
  const data = result.data
  if (data.errors) {
    console.error(data.errors)
  }
  console.log('we got some data?', data)
  const parsedData = AddCardResponseSchema.parse(data.data.addCardRequest)
  return parsedData
}

export const addCard = async (
  organisation: OrganisationWithBranding,
  paymentId: string,
  email?: string
): Promise<boolean> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($organisationUuid: UUID!, $data:UpdateCardInput!) {
            addCard(organisationUuid: $organisationUuid data: $data)
          }`,
      variables: {
        organisationUuid: organisation?.uuid,
        data: {
          stripePaymentMethod: paymentId,
          email: email,
        },
      },
    }),
  })
  const data = result.data
  if (data.errors) {
    console.error(data.errors)
    throw new Error(data.errors.pop().message)
  }
  if (data.data.addCard !== true) {
    throw new Error('Failed to add card')
  }
  return true
}

export const updateCard = async (
  organisation: OrganisationWithBranding,
  paymentId: string,
  email?: string,
  secondary?: boolean
): Promise<BillingCurrent> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
          mutation name($organisationUuid: UUID!, $data:UpdateCardInput!) {
            updateCard(organisationUuid: $organisationUuid data: $data)
            ${currentBillingFields}
          }`,
      variables: {
        organisationUuid: organisation?.uuid,
        data: {
          stripePaymentMethod: paymentId,
          email: email,
          secondary: secondary,
        },
      },
    }),
  })
  const data = result.data
  if (data.errors) {
    console.error(data.errors)
    throw new Error(data.errors.pop().message)
  }
  const parsedData = BillingCurrentSchema.parse(data.data.updateCard)
  return parsedData
}

export const getInvoices = async (
  organisation: OrganisationWithBranding,
  startingAfter?: string
): Promise<BillingInvoiceList> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          getInvoices(organisationUuid: "${organisation.uuid}" startingAfter:"${
            startingAfter || ''
          }") {
            id
            hostedInvoiceUrl
            invoicePdfUrl
            paid
            number
            effectiveAt
            periodStart
            periodEnd
            status
            total
            currency
          }
        }`,
    }),
  })
  const data = result.data
  if (data.errors) {
    console.error(data.errors)
  }
  const parsedData = BillingInvoiceListSchema.parse(data.data.getInvoices)
  return parsedData
}

export const stripeOptions: StripeElementsOptions = {
  mode: 'subscription',
  amount: 5000,
  currency: 'aud',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
}

export const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PK)
