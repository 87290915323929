import { Fragment } from 'react'
import { onChangeInterface } from './TextInputField'

export interface SimpleSelectItemProps {
  value: string | number
  label: string
  disabled?: boolean
}

interface SimpleSelectFieldProps {
  label?: string
  name: string
  options: SimpleSelectItemProps[]
  firstItemText?: string
  disabled?: boolean
  value?: string
  required?: boolean
  handleChange?: (data: onChangeInterface) => void
}

export default function SimpleSelectField({
  label,
  name,
  firstItemText,
  handleChange,
  options,
  disabled,
  value,
  required,
}: SimpleSelectFieldProps) {
  const handleValueChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const element = e.target as HTMLSelectElement
    const label = element.options[element.selectedIndex].label

    handleChange &&
      handleChange({
        name: element.name,
        value: element.value,
        label,
        strValue: element.value,
      })
  }

  return (
    <Fragment>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-dark"
        >
          {label}
        </label>
      )}
      <select
        id={name}
        name={name}
        key={name}
        disabled={disabled ? disabled : false}
        className="outline-none block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900  ring-1 ring-inset ring-gray-dark/30 focus:ring-2 focus:ring-gray-dark/30 sm:text-sm sm:leading-6 shadow-sm dark:bg-dark-gray-light dark:text-white"
        value={value ? value : ''}
        onChange={handleValueChange}
        required={required ? required : false}
      >
        {firstItemText && (
          <option value="" disabled>
            {firstItemText || 'Select an option'}
          </option>
        )}
        {options.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </Fragment>
  )
}
