import { useCallback, useEffect, useState } from 'react'
import '../../../../App.css'

import { useUser } from '../../../../lib/store'
import { useParams } from 'react-router-dom'
import { ResultReferral } from '../../../../lib/validators'

import PageSection from '../../../layout/PageSection'
import { orgResultUrlRegex } from '../../../../lib/routes'
import Page from '../../../Page'
import { getResultsDataForProfileAndUuid } from '../../../../lib/results'
import PageLoader from '../../../layout/PageLoader'
import { ResultViewer } from '../../ResultViewer'
import { useOrganisationContext } from '../../../context/OrganisationContext'

export default function OrgResult() {
  const { org } = useOrganisationContext()
  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState<ResultReferral>()

  const [profile, setProfile] = useState('')

  const user = useUser()
  const params = useParams()

  const getResultsData = useCallback(async (): Promise<ResultReferral> => {
    // setTimeout(async () => {
    if (!user) {
      throw new Error('Missing user')
    }
    if (!params.uuid) {
      throw new Error('Missing url param')
    }

    const parsedData = await getResultsDataForProfileAndUuid({
      resultUuid: params.uuid,
      profileUuid: profile,
      organisationUuid: org!.uuid,
    })
    setResult(parsedData)
    setLoading(false)
    return parsedData
  }, [params.uuid, profile, user])

  useEffect(() => {
    // set the profile to be the first one
    if (user) {
      setProfile(user.profileUuid)
    }
    if (profile) {
      getResultsData()
    }

    // fetch the tests here
  }, [user, profile, getResultsData])

  return (
    <Page>
      <PageSection>
        {!loading && result && org && (
          <ResultViewer
            profileUuid={profile}
            resultItem={result}
            urlRegex={orgResultUrlRegex}
            org={org}
          ></ResultViewer>
        )}
      </PageSection>
      {loading && <PageLoader></PageLoader>}
    </Page>
  )
}
