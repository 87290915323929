import { PropsWithChildren } from "react";
import RoundedSection, { RoundedSectionSize } from "./RoundedSection";

export interface AppWrapperProps extends PropsWithChildren {}

interface PageSectionProps extends PropsWithChildren {
  fullHeight?: boolean;
  centerContent?: boolean;
  size?: RoundedSectionSize
}

export default function PageSection({
  children,
  fullHeight = false,
  centerContent = false,
  size = "2xl"
}: PageSectionProps) {
  const classes: string[] = [];
  if (fullHeight) {
    classes.push("sm:min-h-[calc(100vh-6.5rem)] !py-10");
  }
  if (centerContent) {
    classes.push("flex justify-center items-center text-center");
  }
  return (
    <RoundedSection size={size} className={classes.join(" ")}>
      {children}
    </RoundedSection>
  );
}
