import { Fragment, useEffect, useState } from 'react'
import { authFetch } from '../../../../../providers/AuthProvider'

import {
  OrganisationWithBranding,
  useOrganisationContext,
} from '../../../../context/OrganisationContext'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import toast from 'react-hot-toast'
import {
  BillingCurrent,
  OrganisationApiCredentials,
  OrganisationApiCredentialsSchema,
} from '../../../../../lib/validators'
import PageLoader from '../../../../layout/PageLoader'
import {
  ArrowTopRightOnSquareIcon,
  ClipboardDocumentListIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/outline'
import { Button } from '../../../../form/button/Button'

import { Link } from 'react-router-dom'
import {
  actions,
  useCurrentBilling,
  useOrganisation as getCurrentOrg,
  useRefreshedOrgTrigger,
} from '../../../../../lib/store'
import { getCurrentBilling } from '../../../../../lib/billing'
import { TermsAcceptanceModal } from '../modals/TermsAcceptanceModal'
import {
  generateApiCredentialsForOrganisation,
  updateOrganisation,
} from '../../../../../lib/organisation'
import { refreshCurrentUser } from '../../../../../lib/utils'
import {
  GroupSection,
  GroupSectionItem,
  GroupSections,
} from '../../../../layout/GroupSection'

export default function SettingsTabApiKeys() {
  const { org, theme } = useOrganisationContext()
  const refreshedOrgTrigger = useRefreshedOrgTrigger()
  const billing = useCurrentBilling()

  const [apiCreds, setApiCreds] = useState<
    OrganisationApiCredentials | null | undefined
  >()
  const [loading, setLoading] = useState(true)
  const [canEnableApi, setCanEnableApi] = useState(false)
  const [apiTermsSaving, setApiTermsSaving] = useState(false)
  const [platformTermsSaving, setPlatformTermsSaving] = useState(false)
  const [acceptedApiTerms, setAcceptedApiTerms] = useState<
    string | null | undefined
  >(org?.apiTermsAccepted)
  const [acceptedOrgTerms, setAcceptedOrgTerms] = useState<
    string | null | undefined
  >(org?.orgTermsAccepted)
  const [openAPITermsModal, setOpenAPITermsModal] = useState(false)
  const [openPlatformTermsModal, setOpenPlatformTermsModal] = useState(false)
  const [currentBilling, setCurrentBilling] = useState<
    BillingCurrent | null | undefined
  >()

  const getApiCredentials = async (
    orgUuid: string
  ): Promise<OrganisationApiCredentials | null> => {
    const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        query: `{
            getApiCredentials(organisationUuid: "${orgUuid}") {
              clientId,
              clientSecret,
              active
            }
          }
        `,
      }),
    })
    if (result.data.data.getApiCredentials === null) {
      setApiCreds(null)
      return null
    }
    console.log(result.data.data)
    const creds = OrganisationApiCredentialsSchema.parse(
      result.data.data.getApiCredentials
    )

    setApiCreds(creds)

    return creds
  }

  useEffect(() => {
    console.log('the orgs refreshed? lets do something here.')
    const co = getCurrentOrg()
    if (co) {
      setAcceptedApiTerms(co.apiTermsAccepted)
    }
  }, [refreshedOrgTrigger])

  useEffect(() => {
    async function getBilling(organisation: OrganisationWithBranding) {
      const billingInfo = await getCurrentBilling(organisation)
      // if (billingInfo === null) {
      //   toast.error('No billing info.')
      //   // setLoading(false)
      // }
      setCurrentBilling(billingInfo)
      actions.setCurrentBilling(billingInfo)
      setLoading(false)
    }
    // if we failed to get any api creds, lets load billing.
    if (!billing && org && apiCreds === null) {
      getBilling(org)
    } else if (billing && org) {
      setLoading(false)
      setCurrentBilling(billing)
    }
  }, [apiCreds, billing, org])

  useEffect(() => {
    async function getApiDetails() {
      if (org) {
        const creds = await getApiCredentials(org.uuid)
        if (creds) {
          setLoading(false)
          return
        }
        // we need to request the billing state here now.
        // lets get the billing info now.
      }
    }
    if (org) {
      getApiDetails()
      setAcceptedApiTerms(org.apiTermsAccepted)
      setAcceptedOrgTerms(org.orgTermsAccepted)
    }
  }, [org])

  useEffect(() => {
    if (org && acceptedApiTerms && currentBilling?.defaultPaymentMethod) {
      setCanEnableApi(true)
      return
    }
    setCanEnableApi(false)
  }, [org, acceptedApiTerms, currentBilling?.defaultPaymentMethod])

  const enableApi = async () => {
    if (org) {
      await generateApiCredentialsForOrganisation({
        organisationUuid: org.uuid,
      })
      const response = await getApiCredentials(org.uuid)
      if (response) {
        toast.success('API Enabled', {
          duration: 3000,
        })
      } else {
        toast.error('Failed to enable API, contact support.', {
          duration: 3000,
        })
      }
      return
    }
  }

  return (
    <Fragment>
      {loading && <PageLoader theme={org?.theme || undefined}></PageLoader>}
      {!loading && (
        <GroupSections>
          <GroupSection
            title="Api Keys"
            description="To generate referrals and tests programatically."
          >
            <GroupSectionItem>
              <form>
                {apiCreds === null && org && (
                  <div className="col-span-full">
                    <b>
                      There are currently no api credentials configured for this
                      organisation.
                    </b>
                    <br />
                    <br />
                    If you'd like to start using the api, you need to complete
                    the following steps:
                    <ul className="list-decimal pl-8 mt-4 mb-6">
                      <li className="mt-2">
                        Agree to our Platform Terms of Use
                        <br />
                        {acceptedOrgTerms && <div>✅ Completed</div>}
                        {!acceptedOrgTerms && (
                          <Button
                            label="View Platform Terms"
                            onClick={() => {
                              setOpenPlatformTermsModal(true)
                              setPlatformTermsSaving(true)
                            }}
                            loading={platformTermsSaving}
                            disabled={platformTermsSaving}
                          ></Button>
                        )}
                      </li>
                      <li className="mt-2">
                        Agree to our Api Terms of Use
                        <br />
                        {acceptedApiTerms && <div>✅ Completed</div>}
                        {!acceptedApiTerms && (
                          <Button
                            label="View API Terms"
                            onClick={() => {
                              setOpenAPITermsModal(true)
                              setApiTermsSaving(true)
                            }}
                            loading={apiTermsSaving}
                            disabled={apiTermsSaving}
                          ></Button>
                        )}
                      </li>
                      <li className="mt-2">
                        Add a payment method
                        <br />
                        {currentBilling?.defaultPaymentMethod && (
                          <div>✅ Completed</div>
                        )}
                        {!currentBilling?.defaultPaymentMethod && (
                          <Link
                            to={`/org/${org.id}/settings/billing`}
                            className="text-[--active-color] hover:text-[--active-hover-color]"
                            style={
                              theme
                                ? ({
                                    '--active-color': theme.linkColor,
                                    '--active-hover-color':
                                      theme.linkHoverColor,
                                  } as React.CSSProperties)
                                : {}
                            }
                          >
                            Click here to go to the billing page and add a card
                            to your account.
                          </Link>
                        )}
                      </li>
                      <li className="mt-2">
                        Enable the API
                        <br />
                        <Button
                          label="Enable API"
                          disabled={!canEnableApi}
                          onClick={() => enableApi()}
                        ></Button>
                      </li>
                      <li className="mt-2">
                        View the documentation to begin
                        <br />
                        <a
                          href={`${import.meta.env.VITE_API_URL}/v1/docs`}
                          target="_blank"
                          className="text-[--active-color] hover:text-[--active-hover-color]"
                          style={
                            theme
                              ? ({
                                  '--active-color': theme.linkColor,
                                  '--active-hover-color': theme.linkHoverColor,
                                } as React.CSSProperties)
                              : {}
                          }
                        >
                          Open documentation{' '}
                          <ArrowTopRightOnSquareIcon
                            width={16}
                            className="inline"
                          ></ArrowTopRightOnSquareIcon>
                        </a>
                      </li>
                    </ul>
                    <TermsAcceptanceModal
                      theme={theme || undefined}
                      show={openAPITermsModal}
                      setShow={setOpenAPITermsModal}
                      termsToAcceptUrl={`${import.meta.env.VITE_APP_URL}/legal/BGT_API_Agreement_1.2_2024.pdf`}
                      onAccept={async () => {
                        console.log('Accepted the terms.')
                        if (org) {
                          const response = await updateOrganisation(org?.uuid, {
                            apiTermsAccepted: true,
                          })
                          setAcceptedApiTerms(response.apiTermsAccepted)
                          await refreshCurrentUser()
                          setApiTermsSaving(false)
                        }
                      }}
                    ></TermsAcceptanceModal>
                    <TermsAcceptanceModal
                      theme={theme || undefined}
                      show={openPlatformTermsModal}
                      setShow={setOpenPlatformTermsModal}
                      termsToAcceptUrl="https://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf"
                      onAccept={async () => {
                        console.log('Accepted the terms.')
                        if (org) {
                          const response = await updateOrganisation(org?.uuid, {
                            orgTermsAccepted: true,
                          })
                          setAcceptedOrgTerms(response.orgTermsAccepted)
                          await refreshCurrentUser()
                          setPlatformTermsSaving(false)
                        }
                      }}
                    ></TermsAcceptanceModal>
                  </div>
                )}
                <div className="col-span-full text-md">
                  The REST api is versioned, with the latest version being
                  1.0.0. The base URL for the api can be found at:{' '}
                  <a
                    href={`${import.meta.env.VITE_API_URL}/v1`}
                    className="text-[--active-color] hover:text-[--active-hover-color]"
                    target="_blank"
                    style={
                      org?.theme
                        ? ({
                            '--active-color': org.theme.linkColor,
                            '--active-hover-color': org.theme.linkHoverColor,
                          } as React.CSSProperties)
                        : {}
                    }
                  >
                    {import.meta.env.VITE_API_URL}/v1/
                  </a>{' '}
                  <p className="mt-4">
                    <a
                      href={`${import.meta.env.VITE_API_URL}/v1/docs`}
                      target="_blank"
                      className="text-[--active-color] hover:text-[--active-hover-color]"
                      style={
                        org?.theme
                          ? ({
                              '--active-color': org.theme.linkColor,
                              '--active-hover-color': org.theme.linkHoverColor,
                            } as React.CSSProperties)
                          : {}
                      }
                    >
                      Swagger Documentation
                    </a>
                  </p>
                </div>
                {apiCreds && (
                  <Fragment>
                    <div className="col-span-full rounded-lg bg-gray-light dark:bg-dark-gray-light p-4 max-w-lg mb-2">
                      <ApiCredentialWithCopy
                        value={apiCreds.clientId}
                        successMessage="Client Id copied to clipboard"
                        label="Client Id"
                      ></ApiCredentialWithCopy>
                    </div>
                    <div className="col-span-full rounded-lg bg-gray-light dark:bg-dark-gray-light p-4 max-w-lg">
                      <ApiCredentialWithCopy
                        value={apiCreds.clientSecret}
                        successMessage="Client Secret copied to clipboard"
                        label="Client Secret"
                        defaultPrivate={true}
                      ></ApiCredentialWithCopy>
                    </div>
                  </Fragment>
                )}
              </form>
            </GroupSectionItem>
          </GroupSection>
        </GroupSections>
      )}
    </Fragment>
  )
}

interface ApiCredentialWithCopyProps {
  successMessage: string
  value: string
  label: string
  defaultPrivate?: boolean
}
function ApiCredentialWithCopy({
  successMessage,
  value,
  label,
  defaultPrivate,
}: ApiCredentialWithCopyProps) {
  const [isBlurred, setIsBlurred] = useState(defaultPrivate)

  return (
    <Fragment>
      <label className="block text-sm font-medium leading-6 text-gray-dark">
        {label}
      </label>

      <div className="grid grid-cols-12 gap-x-4">
        <div
          className={`break-words font-[courier] col-span-11 pointer-events-none ${
            isBlurred ? 'blur' : ''
          }`}
        >
          {value}
        </div>
        <span className="cursor-pointer align-middle content-center col-span-1">
          <CopyToClipboard
            text={value}
            onCopy={() =>
              toast.success(successMessage, {
                duration: 3000,
              })
            }
          >
            <ClipboardDocumentListIcon
              width={20}
              title="Copy to clipboard"
              className="inline cursor-pointer"
            ></ClipboardDocumentListIcon>
          </CopyToClipboard>
          {defaultPrivate !== undefined && (
            <Fragment>
              {!isBlurred && (
                <EyeIcon
                  width={20}
                  className="mt-2"
                  onClick={() => setIsBlurred(true)}
                ></EyeIcon>
              )}
              {isBlurred && (
                <EyeSlashIcon
                  width={20}
                  className="mt-2"
                  onClick={() => setIsBlurred(false)}
                ></EyeSlashIcon>
              )}
            </Fragment>
          )}
        </span>
      </div>
    </Fragment>
  )
}
