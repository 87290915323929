import { Fragment, useEffect, useState } from 'react'
import { TabItems } from '../../lib/interfaces'
import {
  Biomarker,
  GroupedBiomarkers,
  ResultReferral,
} from '../../lib/validators'
import { PageHeading } from '../layout/PageHeading'
import { getPrettyDate } from '../../lib/utils'
import { TabbedMenu } from '../layout/TabbedMenu'
import { ResultTabOverview } from './result-tabs/ResultTabOverview'
import { ResultTabBiomarkers } from './result-tabs/ResultTabBiomarkers'
import { ResultTabAttachments } from './result-tabs/ResultTabAttachments'
import { useLocation } from 'react-router-dom'
import { OrganisationWithBranding } from '../context/OrganisationContext'

interface ResultViewerProps {
  resultItem: ResultReferral
  profileUuid: string
  urlRegex: string
  org?: OrganisationWithBranding
}

const baseTabs: TabItems = {
  '': {
    name: 'Overview',
    href: '',
    current: false,
  },
  biomarkers: {
    name: 'Biomarkers',
    href: 'biomarkers',
    current: false,
  },
  attachments: {
    name: 'Attachments',
    href: 'attachments',
    current: false,
  },
}

export function ResultViewer({
  resultItem,
  profileUuid,
  urlRegex,
  org,
}: ResultViewerProps) {
  const [currentPage, setCurrentPage] = useState<string>('')
  const [groupedBiomarkers, setGroupedBiomarkers] = useState<GroupedBiomarkers>(
    []
  )
  const [result, setResult] = useState<ResultReferral>(resultItem)
  const [tabs, setTabs] = useState<TabItems>(baseTabs)

  const location = useLocation()

  useEffect(() => {
    handlePageLoad(location.pathname)
  }, [location])

  useEffect(() => {
    console.log('+++ Handle page load.')
    handlePageLoad(window.location.pathname)
  }, [])

  const getTabs = (page: string) => {
    Object.entries(tabs).map(([key, val]) => {
      val.current = false
      if (key === page) {
        val.current = true
      }

      return val
    })
    return tabs
  }

  const handlePageLoad = (pathname: string): void => {
    // console.log("++++ handle page load");
    const re = new RegExp(urlRegex)
    console.log(urlRegex, pathname)
    const matches = re.exec(pathname)
    // console.log("matches", matches, re.test(location.pathname));
    if (!matches || matches.length === 0) {
      throw new Error('Tab not found')
    }

    const tab = matches.pop()?.replace('/', '')
    if (tab === undefined) {
      throw new Error('Tab not found')
    }

    setCurrentPage(tab)
    const latestTabs = getTabs(tab)
    setTabs(latestTabs)
  }

  useEffect(() => {
    setResult(resultItem)
    if (resultItem && resultItem.biomarkers) {
      transformBiomarkersIntoCategories(resultItem.biomarkers)
    }
  }, [resultItem])

  const transformBiomarkersIntoCategories = (biomarkers: Biomarker[]): void => {
    // console.log("lets transform them here...", biomarkers);
    const categoryGroups: GroupedBiomarkers = []
    const categories = Object.fromEntries(
      new Map(
        biomarkers.map((item) => [item.category?.id, item.category])
      ).entries()
    )
    const categorySlugs = Object.keys(categories)
    categorySlugs.sort()
    // for each category
    // this way is so slow, make it faster in future
    categorySlugs.forEach((category) => {
      const biomarkersFound: Biomarker[] = []
      for (const b in biomarkers) {
        if (biomarkers[b].category?.id === category) {
          biomarkersFound.push(biomarkers[b])
        }
      }

      // pluck the biomarker out
      categoryGroups.push({
        category: categories[category],
        biomarkers: biomarkersFound,
      })
    })

    setGroupedBiomarkers(categoryGroups)
  }

  return (
    <Fragment>
      <PageHeading
        title={result?.referral?.testName || 'Results'}
        description={result.referral?.product?.description || ''}
      >
        {result.referral && (
          <Fragment>
            <span className="text-gray-semi-dark dark:text-gray-dark">
              Reference:{' '}
            </span>
            <span className="mr-2">{result?.referral.reference}</span>
          </Fragment>
        )}
        {'    '}
        <span className="text-gray-semi-dark dark:text-gray-dark">
          Result date:{' '}
        </span>
        {getPrettyDate(result?.createdAt)}
      </PageHeading>

      <TabbedMenu tabs={tabs} currentPage={currentPage}></TabbedMenu>
      {currentPage !== null && currentPage === '' && (
        <ResultTabOverview
          resultReferral={result}
          profile={profileUuid}
        ></ResultTabOverview>
      )}
      {currentPage !== null && currentPage === 'biomarkers' && (
        <ResultTabBiomarkers
          groupedBiomarkers={groupedBiomarkers}
          profile={profileUuid}
        ></ResultTabBiomarkers>
      )}
      {currentPage !== null && currentPage === 'attachments' && (
        <ResultTabAttachments
          resultReferral={result}
          profile={profileUuid}
          org={org}
        ></ResultTabAttachments>
      )}
      {currentPage === null && <div>Unknown page</div>}
    </Fragment>
  )
}
